export default {
    data(){
        return {
            acceptPdf: '.pdf',
            acceptDoc: '.doc, .docx',
            acceptExcel: '.xls, .xlsx',
            acceptJpg: '.jpg',
            acceptPdfAndImage: '.jpg, .png, .gif, .webp, .pdf',
            acceptImage: '.jpg, .png, .gif, .webp',
            acceptDocImage: '.doc, .docx, .jpg, .png, .gif, .webp',
            acceptGeneral: '.doc, .docx, .pdf, .xls, .xlsx, .jpg, .png, .webp, .gif',
        }
    },
    computed: {
        isAdmin() {
            return this.$store.state.isAdmin === '01';
        },
    },
    methods: {
        getDrawerTitle(state){
            return !state ? '新建' :
                state === 1 ? '编辑' :
                state === 2 ? '查看' :
                state === 3 ? '填报' :
                state === 4 ? '审核' :
                state === 5 ? '审批' :
                state === 6 ? '确认' :
                state === 7 ? '接收' : ''
        },
        generalVisibilityChange(event, item, key){
            let ev = event.target;
            let ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
            let content_weight = ev.clientWidth;// 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
            // console.log('visibilityChange', event, ev_weight, content_weight);
            if (ev_weight > content_weight) {
                // 实际宽度 > 可视宽度  文字溢出
                item[key] = false;
                this.$forceUpdate();
            } else {
                // 否则为不溢出
                item[key] = true;
                this.$forceUpdate();
            }
            // console.log('visibilityChange', ev, ev_weight, content_weight, item, key, item[key]);
        },
        getDeviceStateName(state){
            return state === '01' ? '运行' :
                state === '02' ? '停用' :
                state === '03' ? '报废' :
                state === '04' ? '送检' :
                state === '05' ? '封存' : '';
        },
        verifyConfirm(params){
            return new Promise(resolve => {
                this.$verifyConfirm.show({
                    successCallback: () => {
                        console.log('verifyConfirm-验证成功');
                        resolve(true);
                    },
                    ... (params || {})
                });
            });
        },
         /**
         * @description 文件格式-pdf
         */
         beforePdfUpload(file){
            let reg = /.pdf$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传pdf格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式-xls、xlsx
         */
        beforExcelUpload(file){
            let reg = /.xlsx?$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传xls、xlsx格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式-xls
         */
        beforXlsUpload(file){
            let reg = /.xls$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传xls格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式-xlsx
         */
        beforXlsxUpload(file){
            let reg = /.xlsx$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传xlsx格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式-doc、docx
         */
        beforeDocUpload(file){
            let reg = /.docx?$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传doc、docx格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式-jpg
         */
        beforeJpgUpload(file){
        /**
         * @description 文件格式jpg格式校验
         */
            let reg = /.jpg$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传jpg格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式jpg、png、webp、gif格式校验
         */
        beforeImageUpload(file){
            let reg = /(.jpg|.png|.webp|.gif)$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传jpg、png、webp、gif格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式doc、docx、jpg、png、webp、gif格式校验
         */
        beforeDocImgUpload(file){
            let reg = /(.docx?|.jpg|.png|.webp|.gif)$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传doc、docx、jpg、png、webp、gif格式'
                });
            };
            return reg.test(file.name)
        },
          /**
         * @description pdf、jpg、png、webp、gif格式校验
         */
          beforePdfImgUpload(file){
            let reg = /(.pdf|.jpg|.png|.webp|.gif)$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传pdf、jpg、png、webp、gif格式'
                });
            };
            return reg.test(file.name)
        },
        /**
         * @description 文件格式通用检验-doc、docx、pdf、xls、xlsx、jpg、png、webp、gif格式校验
         */
        beforeGeneralUpload(file){
            let reg = /(.docx?|.pdf|.xlsx?|.jpg|.png|.webp|.gif)$/;
            if (!reg.test(file.name)){
                this.$message({
                    type: 'warning',
                    message: '只支持上传doc、docx、pdf、xls、xlsx、jpg、png、webp、gif格式'
                });
            };
            return reg.test(file.name)
        },

        beforeFileProhibitUpload(file) {
            const Exe = file.name.match(/\.([^\.]+)$/)[1] === "exe";
            const Bat = file.name.match(/\.([^\.]+)$/)[1] === "bat";
            const Js = file.name.match(/\.([^\.]+)$/)[1] === "js";
            const Sh = file.name.match(/\.([^\.]+)$/)[1] === "sh";
            const Vba = file.name.match(/\.([^\.]+)$/)[1] === "vba";
            if (Exe || Bat || Js || Sh || Vba) {
                this.$message({
                    message: "禁止上传：.exe/.bat/.js/.sh/.vba 文件",
                    type: "warning",
                });
            }
            return !Exe && !Bat && !Js && !Sh && !Vba;
        },
        maxfile() {
            this.$message({
                message: "只支持上传一个标准文件!",
                type: "warning",
            });
        },
    }
}