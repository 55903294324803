//ChoiceStandard.js文件
import ChoiceStandard from './index.vue';
export default {
    install(vm) {
        const $app = vm.extend(ChoiceStandard);
        const $choiceStandard = new $app().$mount(document.createElement('div'))
        document.body.appendChild($choiceStandard.$el)
        vm.prototype.$choiceStandard = {
            show: (params) => {
                Object.keys(params).forEach(key => {
                    $choiceStandard[key] = params[key]
                });
                $choiceStandard.visible = true;
            },
            hide: () => {
                $choiceStandard.visible = false
            }
        }
    }
}