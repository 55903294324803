/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-09 16:35:40
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-09-14 15:04:44
 * @FilePath: \product-equip-view\src\views\dictionary.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * 设备模块
 */
 //是否溯源
export const isSourceOptions =
 [
  {
    label: "是",
    value: "01",
  },
  {
    label: "否",
    value: "02",
  },
]
   //完成情况
 export const   finishResultOptions= 
   [
      {
        label: '未完成',
        value: '01',
      },
      {
        label: '已完成',
        value: '02',
      },
    ]
   //是否过期
 export const   overdueOptions= 
   [
      {
        label: '未过期',
        value: '06',
      },
      {
        label: '即将过期',
        value: '07',
      },
      {
        label: '已过期',
        value: '08',
      },
    ]


 //CNAS能力内外
 export const isAbilityOptions = 
  [
  {
    label: "能力内",
    value: "01",
  },
  {
    label: "能力外",
    value: "02",
  },
]
//接受时状态
export const  acceptStateOptions =
  [
  {
    label: "完好",
    value: "01",
  },
  {
    label: "有缺陷",
    value: "02",
  },
]
// 设备状态
export const equimentOptions = 
  [
    { label: '运行', value: '01' },
    { label: '停用', value: '02'},
    { label: '报废', value: '03' },
    { label: '送检', value: '04' },
    { label: '封存', value: '05' },
  ]
//是否为永久设备
export const isForeverOptions = 
  [
    { label: '永久设备', value: '01' },
    { label: '非永久设备', value: '02'},
  ]
  // 检定/校准/验证方式
  export const traceTypeOptions = 
  [
    { label: '检定', value: '01' },
    { label: '校准', value: '02'},
    { label: '测试', value: '03'},
  ]
    //证书类型
  export const  certificateTypeOptions=
      [
       { label: "检定证书", value: "01" },
       { label: "校准证书", value: "02" },
     ]
       //专业领域
   export const deviceClassOptions =
     [
        {
          value: "01",
          label: "长度",
        },
        {
          value: "02",
          label: "电磁",
        },
        {
          value: "03",
          label: "光学",
        },
        {
          value: "04",
          label: "力学",
        },
        {
          value: "05",
          label: "热工",
        },
        {
          value: "06",
          label: "声学",
        },
        {
          value: "07",
          label: "无线电",
        },
        {
          value: "08",
          label: "电离辐射",
        },
        {
          value: "09",
          label: "时间频率",
        },
        {
          value: "10",
          label: "物理化学",
        },
        {
          value: "99",
          label: "其他专业",
        },
      ]


  /**
   *  标准
   */

  // 标准类型
  export const standardTypeOptions =
  [
   {
      value: "01",
      label: "国家标准",
  },
  {
      value: "02",
      label: "行业标准",
  },
  {
      value: "03",
      label: "地方标准",
  },
  {
      value: "04",
      label: "团体标准",
  },
  {
      value: "05",
      label: "企业标准",
  },
  {
      value: "06",
      label: "国际标准",
  },
  {
      value: "99",
      label: "非标准方法",
  }, 
  ]
  //标准状态
  export const standardStateOptions =[
    {
      value: "01",
      label: "现行",
      color:'rgb(10, 138, 85)'
  },
  {
      value: "02",
      label: "被替代",
      color:'rgb(255, 0, 0)'
  },
  {
      value: "03",
      label: "废止",
      color:'#565656'
  },
  {
      value: "04",
      label: "待实施",
      color:'orange'
  },
  ]
  // 标准方法
  export const isStandardOptions =[
    {
      value: "01",
      label: "是",
   },
   {
      value: "02",
      label: "否",
   },
  ]

  /**
   * 能力验证/比对计划
   */

      //计划类型
  export const planTypeOptions = [
          {
            label: '能力验证计划',
            value: '01'
          },
          {
            label: '测量审核计划',
            value: '02'
          },
        ]
      // 能力验证计划类别
  export const   abilityTypeOptions = [
          {
            label: '市场监管总局(国家认监委)能力验证',
            value: '01'
          },
          {
            label: '国家有关行业主管部门能力验证',
            value: '02'
          },
          {
            label: '省级能力验证',
            value: '03'
          },
          {
            label: '国内能力验证提供者',
            value: '04'
          },
          {
            label: '国际能力验证提供者和国家相关组织',
            value: '05'
          },
          {
            label: '其他能力验证',
            value: '06'
          },
        ]

  // 完成情况
 export const performStateOptions= [
      {
        label: '未完成',
        value: '01',
        color: 'red'
      },
      {
        label: '已完成',
        value: '02',
        color: 'rgb(10, 138, 85)'
      },
    ]
   
  /**
   * 人员监督计划
   */
    // 监督方式
 export   const   superviseTypeOptions=[
        {
          label: '观察现场',
          value: '01'
        },
        {
          label: '模拟检测/校准',
          value: '02'
        },
        {
          label: '记录及报告复合',
          value: '03'
        },
        {
          label: '其他',
          value: '04'
        },

      ]

      //人员培训计划
      
      // 培训单位
   export   const   trainingUnitOptions=[
    {
      label: '院综合管理部',
      value: '01'
    },
    {
      label: '检测中心/检测部',
      value: '02'
    },
    {
      label: '外部',
      value: '03'
    },

      ]



