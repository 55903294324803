//choiceObject.js文件
import ChoiceTree from "./index.vue";
export default {
  install(vm) {
    const $app = vm.extend(ChoiceTree);
    const $ChoiceTree = new $app().$mount(document.createElement("div"));
    document.body.appendChild($ChoiceTree.$el);
    vm.prototype.$ChoiceTree = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $ChoiceTree[key] = params[key];
        });
        console.log("ChoiceTree", params);
        $ChoiceTree.visible = true;
      },
      hide: () => {
        $ChoiceTree.visible = false;
      },
    };
  },
};
