/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-11-14 16:27:12
 * @FilePath: \product-equip-view\src\api\global.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-10-12 17:55:06
 * @FilePath: \product-equip-view\src\api\global.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

const Server = "//" + window.location.hostname + ":";
// const devServer = process.env.NODE_ENV === "development"?'http://192.168.23.27:13000':'';
const devServer =  '';
// const devServer = 'http://47.104.68.37:13012';
// const newServer="http://118.178.184.218:13012"
// const oldServer = 'http://47.104.68.37:13012';
/** 生成地址 开发环境为/api代理  生产环境为本地localhost */
const localGenerateBaseUrl = process.env.NODE_ENV === "development" ? '/api' : 'http://localhost:23489';
const ssoServer = process.env.NODE_ENV === "development" ? devServer : '';
const WebsocketUrl = process.env.NODE_ENV === "development" ? 'ws://192.168.23.27:13000' : 'ws://118.178.184.218:13050';
const ifrServerLogout="http://47.104.68.37:13016/";
export default {
    ssoServer,
}

export {
	/** 接口地址 */
    ssoServer,
    ifrServerLogout,
	/** 报告生成地址 */
    localGenerateBaseUrl,
	/** websocket地址 */
    WebsocketUrl
}
