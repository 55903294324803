import Vue from "vue"
import VueRouter from "vue-router";
import store from "@/store";
import { isElectron, ipcRenderer, isDev } from "@/core/Electron";
import {
    setLocalToken,
    getLocalToken,
    removeLocalToken,
} from '@/core/token';
Vue.use(VueRouter)
//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush = VueRouter.prototype.push

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果成功 调用原来的push方法
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        )
    }
}

import { constantLayoutRoutes,detailRouters } from './config';

/** 登录 */
// const Login = () => import("@/views-dev/Login");

/** 注册 */
// const Register = () => import("@/views-dev/Register");

/** 网络无连接 路由组件 */
const Offline = () => import("@/views-dev/Offline");

/** 首页路由地址 */
const HomePath = '/home';

/** 网络无连接 路由地址 */
const OfflinePath = '/Offline';

/** 布局路由组件 */
const Layout = () => import("@/components-dev/Layout");
// LoginPath, LoginName, Login
import {
    RegisterPath, RegisterName, Register,LoginName,LoginPath,Login,
    ChangePasswordPath,ChangePasswordName, ChangePassword } from '@/core/index';


const routes = [
    // 后台登录
    {
        path: LoginPath,
        name: LoginName,
        component: Login,
        // beforeEnter(to, from, next){
        //     // store.commit('setDocumentFrontTitle');
        //     next();
        // },
        meta: {
            title: "登录",
        },
    },
    // {
    //     path: "/register",
    //     name: "Register",
    //     component: Register,
    //     meta: {
    //         title: "注册",
    //     },
    // },
      {
        path: RegisterPath,
        name: RegisterName,
        component: Register,
        meta: {
            title: "注册",
        },
    },
    {
        path: '/ChangePassword',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            title: '修改密码',
        }
    },
    {
        path: OfflinePath,
        name: 'Offline',
        component: Offline,
    },
    {
        path: "/",
        name: "Layout",
        redirect: HomePath,
        component: Layout,
        // beforeEnter(to, from, next){
        //     console.log('LandingPage.beforeEnter');
        //     // store.commit('setDocumentFrontTitle');
        //     next();
        // },
        children: [...constantLayoutRoutes,...detailRouters],
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

//判断用户是否登录
/** 是否为初次加载当前token对应用户信息 */
let firstLoad = true;
// 当前环境为electron 本地token存在 且为生产模式 清除token
if (isElectron && getLocalToken() && !isDev){
    removeLocalToken();
}

// 路由守卫
router.beforeEach(async (to, from, next) => {
    /** query传递的token值 多用于系统或门户之间跳转 */
    let queryToken = to.query.token;
    // query传递的token值存在 设置token至本地
    if (queryToken) {
        setLocalToken(queryToken);
    }
    // 当前环境为electron 本地token存在 且为初次加载 直接设置当前窗口token
    else if (isElectron && getLocalToken() && firstLoad && isDev){
        setLocalToken(getLocalToken());
    }
    
    let loginPaths = [LoginPath, RegisterPath,ChangePasswordPath]
    let token = getLocalToken();
    // console.log('beforeEach', token, firstLoad, to.path, from.path);
    /** 需要重定向的路由地址 */
    let redirect;
    // 网络无连接  来源路由地址不是OfflinePath，此时需要跳转OfflinePath，并传入来源路由地址from.query.redirect或from.fullPath做redirect参数
    if (!window.navigator.onLine && to.path === OfflinePath && !to.query.redirect && from.path !== OfflinePath){
        redirect = encodeURIComponent(from.fullPath);
        console.log('网络无连接, redirect=', redirect);
        next({
            path: OfflinePath,
            query: {
                redirect,
            },
            replace: true
        });
    }
    // 网络无连接  目标路由是OfflinePath，此时需要跳转OfflinePath，并传入目标路由做redirect参数
    else if (!window.navigator.onLine && to.path !== OfflinePath && from.path === OfflinePath){
        next({
            path: from.fullPath,
            replace: true,
        });
    }
    else if (!window.navigator.onLine && to.path !== OfflinePath && from.path !== OfflinePath){
        redirect = encodeURIComponent(to.fullPath);
        console.log('网络无连接, redirect=', redirect);
        next({
            path: OfflinePath,
            query: {
                redirect,
            },
            replace: true
        });
    }
    else if (!window.navigator.onLine){
        next();
    }
    // 网络已连接 目标路由地址为OfflinePath，此时需要重定向
    else if (to.path === OfflinePath){
        if (to.query.redirect){
            redirect = decodeURIComponent(decodeURIComponent(to.query.redirect));
        } else {
            redirect = '/';
        }
        console.log('网络已连接, redirect=', redirect, to.fullPath);
        next({
            path: redirect,
            replace: true
        })
    }
    // 当前窗口token存在
    else if (token) {
        
        if (firstLoad) {
            await Promise.all([
                store.dispatch('getUserInfo'),
            ]);
            firstLoad = false;
        }
        //去往登录/注册页/忘记密码 拦截并发送登录请求
        if (loginPaths.includes(to.path)) {
            console.log('token.redirect', from.query, from.query.redirect);
            let redirect = HomePath;
            if (from.query.redirect){
                redirect = decodeURIComponent(decodeURIComponent(from.query.redirect));
            }
            next({
                path: redirect,
                replace: true
            })
        }
       
        
        else if (from.query.redirect && decodeURIComponent(decodeURIComponent(from.query.redirect)) !== to.fullPath){      
            next({
                path: decodeURIComponent(decodeURIComponent(from.query.redirect)),
            })
        }
        else {
            // console.log('token-existed, non-redirect', token, to.path, from.path);
            next()
            if (isElectron && loginPaths.includes(from.path)){
                ipcRenderer.send('setMainWindow');
            }
        }
    }
    // 当前窗口token不存在
    else {
        if (loginPaths.includes(to.path)) {
            firstLoad = true;
            next()
            console.log('else, ', to.path, from.path);
            // && !(loginPaths.includes(from.path) && loginPaths.includes(to.path))
            if (isElectron){
                ipcRenderer.send('setLoginWindow');
            } 
        }
        else {
            next({
                path: LoginPath,
                query: {
                    redirect: to.fullPath
                }
            })
        }
    }

})

window.addEventListener('offline', () => {
    console.log('网络断开');
    router.push({
        path: OfflinePath
    });
})
window.addEventListener('online', () => {
    /** 网络连接时，需要重定向的路由 */
    let redirect = router.currentRoute.query.redirect;
    if (redirect){
        redirect = decodeURIComponent(decodeURIComponent(redirect));
    } else {
        redirect = '/'
    }
    console.log('网络连接', redirect);
    router.push({
        path: redirect
    });
})
export default router
// export const routerMenu = routes[0].children[0].children.filter(item => item.meta.showInSideMenu)
export {
    routes,
    // BusinessDataRoutes,
    HomePath,
    OfflinePath
}