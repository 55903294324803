<template>
 <div class="container">
  <header>
    <slot name="header" :user="user"></slot>
  </header>
  <main>
    <slot :age="age"></slot>
  </main>
  <footer>
    <slot name="footer"></slot>
  </footer>
</div>
</template>

<script>
export default {
  name: 'basicLayOut',

  data() {
    return {
      user:{
        name:'张三',
        age:20
      },
      age:18
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>