<template>
    <div id="choiceStandard">
        <el-dialog class="choiceStandardDialog" :close-on-click-modal="false" :title="title" :visible.sync="visible"  :modal-append-to-body="false">
            <div class="search-wrapper custom-input">
                <!-- <button class="my-button-plain" @click="handleSourceUnit">维护溯源单位</button> -->
                <div></div>
                <div class="search-input">
                    <el-input size="small" v-model="queryData" placeholder="请输入查询内容" @keyup.enter.native="handleSearch"></el-input>
                    <el-button style="background:#0F7C4F" type="primary" size="small" @click="handleSearch">查询</el-button>
                </div>
            </div>
            <div class="table-body">
                <el-table ref="table_ref" :data="tableData" border v-loading="table_loading" highlight-current-row
                    :header-cell-style="{ background: 'rgb(248, 248, 248)', color: 'rgb(51, 51, 51)' }" @select="handleSelectRow"
                >
                    <el-table-column type="selection" width="45" label="选择">
                        <template slot="header">
                            选择
                        </template>
                    </el-table-column>
                    <el-table-column label="序号" type="index" width="50px">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="standardNo" label="依据号">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="standardName" label="依据名称">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="standardType" label="标准类型" width="150">
                        <template slot-scope="scope">
                            {{
                                scope.row.standardType === '01' ? '国家标准' :
                                scope.row.standardType === '02' ? '行业标准' :
                                    scope.row.standardType === '03' ? '地方标准' :
                                        scope.row.standardType === '04' ? '团体标准' :
                                            scope.row.standardType === '05' ? '企业标准' :
                                                scope.row.standardType === '06' ? '国际标准' : ''
                            }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" :current-page="pagination.pageNum"
                    @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 50]"
                    :page-size="pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div>
            <div class="action-button">
                <el-button class="my-button-green-1" @click="handleSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Button, Dialog } from 'element-ui';
import store from '@/store';
export default {
    name: "ChoiceStandard",
    components: {
        'el-button': Button,
        'el-dialog': Dialog,
    },
    data() {
        return {
            visible: false,
            title: '选择标准',
            table_loading: false,
            queryData: '',
            tableData: [],
            pagination: {
                pageNum: 1,
                pageSize: 15,
                total: 0,
            },
            selectedData: [],
            successCallback: null,
        };
    },
    created(){
        // console.log('created, choiceStandard');
    },
    mounted(){
        // console.log('mounted, choiceStandard');
    },
    computed: {
        userInfo() {
            return store.state.userInfo;
        },
        currentSystem() {
            return store.state.currentSystem;
        },
    },
    watch: {
        async visible(val){
            if (val){
                this.queryData = '';
                this.selectedData = [];
                this.handleSearch();
            }
        },
    },
    methods: {
        getTableData(){
            this.table_loading = true;
            this.api.postFormAPIStandard(
                {
                        ...this.pagination,
                        filters: [
                            {
                                fieldName: "standardInfo.standardName",
                                operator: "LIKE",
                                connectOperator: "AND",
                                fieldValue: this.queryData,
                                groupName: "_s"
                            },
                            {
                                fieldName: "standardInfo.standardNo",
                                operator: "LIKE",
                                connectOperator: "OR",
                                fieldValue: this.queryData,
                                groupName: "_s"
                            }
                        ],
                        sorters: [
                            {
                                sortField: "standardInfo.createTime",
                                direction: "DESC"
                            }
                        ]
                }, 'queryStandard'
            ).then(res => {
                console.log('getStandardTableData', res.data);
                this.tableData = res.data.data.records || [];
                this.pagination.total = res.data.data.total;
                this.table_loading = false;
            }).catch(error => {
                this.table_loading = false;
             })
        },
        handleSizeChange(pageSize){
            this.pagination.pageSize = pageSize;
            this.getTableData();
        },
        handleCurrentChange(pageNum){
            this.pagination.pageNum = pageNum;
            this.getTableData();
        },
        handleSearch(){
            this.pagination = {
                ...this.$options.data().pagination,
            };
            this.getTableData();
        },
        handleSelectRow(selection, row){
            console.log('handleSelectRow', selection, row);
            // if (!selection.length){
            //     this.selectedData = [];
            // } else {
                
            // }
            if (this.selectedData.findIndex(item => item.id === row.id) === -1){
                this.$refs.table_ref.clearSelection();
                this.$refs.table_ref.toggleRowSelection(row, true);
                this.selectedData = [{ ...row }];
            } else {
                this.$refs.table_ref.clearSelection();
                this.selectedData = [];
            }
        },
        handleSubmit() {
            if (!this.selectedData.length){
                return this.$message({
                    message: "请选择一项标准",
                    type: "warning",
                });
            }
            if (typeof this.successCallback === 'function'){
                this.successCallback(this.selectedData);
            };
            this.visible = false;
        },
        handleClose()
        {
            this.visible = false;
            this.selectedData = [];
        },
    },
};
</script>
<style lang="less">
    #choiceStandard{
        &>.choiceStandardDialog{
            &>.el-dialog{
                width: 1000px!important;
                height: 540px;
                & div{
                    box-sizing: border-box;
                }
    //             &>.el-dialog__header {
    //     padding: 12px 10px !important;
    //     background: var(--main-bg-color) !important;

    //     &>.el-dialog__title {
    //       font-size: 16px !important;
    //       color: white !important;
    //       font-weight: bold !important;
    //     }

    //     &>.el-dialog__headerbtn {
    //       position: absolute;
    //       top: 20px;
    //       right: 20px;
    //       padding: 0;
    //       background: 0 0;
    //       border: none;
    //       outline: 0;
    //       cursor: pointer;
    //       font-size: 16px;

    //       &>.el-dialog__close {
    //         color: white !important;
    //         font-size: 21px !important;
    //         position: relative !important;
    //         top: -6px !important;
    //       }
    //     }
    //   }
    & .el-dialog__header {
    flex-shrink: 0;
    padding: 15px 15px 10px !important;
    border-bottom: 2px solid #ebeef5;
    font-weight: bold;
    color: #303133;
    font-size: 14px;
    line-height: 1;
    position: relative;
    & .el-dialog__title{
      font-size: 14px;
    }

  }

                &>.el-dialog__body {
                    height: calc( 100% - 48px );
                    display: flex;
                    flex-flow: column nowrap;
                    padding: 15px 20px;
                    overflow: hidden;
                    &>.search-wrapper{
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 0 10px;
                        &>.search-input{
                            width: 50%;
                            display: flex;
                            align-items: center;
                        }
                    }
                    &>.table-body{
                        overflow: hidden;
                        display: flex;
                        flex-flow: column nowrap;
                        flex-grow: 1;
                        &>.el-table{
                            flex-grow: 1;
                            overflow-y: auto !important;
                            & .el-table__header{
                                &>thead{
                                    &>tr{
                                        th{
                                            &:first-child{
                                                &>.cell{
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            & .el-table__body-wrapper{
                                overflow-y: auto !important;
                            
                            }
                        }
                        &>.el-pagination{
                            text-align: right;
                            flex-shrink: 0;
                            margin: 10px 0 10px;
                        }
                    }
                    &>.action-button{
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                    }
                }   
            }
        }
    }
</style>