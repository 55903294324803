// import http from '../utils/http'
import http from './http';
import { ssoServer } from './global';

let base = ssoServer;
// let tenant =  ssoServer + '/v1.0/tenant';
/** 基础服务  统一使用阿里云服务 */
let tenant =  '/unifyauth/tenant/';
let resourceBaseUrl = ssoServer + '/v1.0/resource/';
// let dataset = ssoServer + '/v1.0/dataset/dataset/'; //数据集-数据集
let connect = ssoServer + '/v1.0/connect/connect/'; //连接-数据集
let template = ssoServer + '/v1.0/templateManage/'; //模板库
/** 登录认证服务  统一使用阿里云服务 */
let sso =  '/unifyauth/sso/';
let lab = ssoServer+ '/v1.0/lab/'; //lab
let equip = ssoServer+'/v1.0/device/';// 设备
let standard = ssoServer+'/v1.0/standard/'; //标准
let staff = ssoServer+'/v1.0/staff/'; //人员
let supplier = ssoServer+'/v1.0/supplier/'; //供应方
let qualification = ssoServer+'/v1.0/lab/qualification/'; //资质
let qualityActivityDevice = ssoServer+'/v1.0/qualityActivity/device/'; //质量活动-设备相关
let qualityActivity = ssoServer+'/v1.0/qualityActivity/'; //质量活动
let qualityAudit = ssoServer+'/v1.0/qualityAudit/'; //管理评审
export default class api {

    //==========↓==========↓==========↓==========↓==========GET==========↓==========↓==========↓==========↓==========

    /**
     *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
     *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
     */
    static getListAPI(params, url, config) {
        return http.get(params, `${base}${url}`, config)
    }
    static getListAPISso(params, url, config) {
        return http.get(params, `${sso}${url}`, config)
    }
    static getListAPItenant(params, url, config) {
        return http.get(params, `${tenant}${url}`, config)
    }
    static getListAPIResource(params, url, config) {
        return http.get(params, `${resourceBaseUrl}${url}`, config)
    }
    static getListAPITemplate(params, url, config) {
        return http.get(params, `${template}${url}`, config)
    }
    static getListAPIConnect(params, url, config) {
        return http.get(params, `${connect}${url}`, config)
    }

    // 设备检测

    // 部门
    static getListAPILab(params, url, config) {
        return http.get(params, `${lab}${url}`, config)
    }
     // 设备
     static getListAPIDevice(params, url, config) {
        return http.get(params, `${equip}${url}`, config)
    }
     // 标准
     static getListAPIStandard(params, url, config) {
        return http.get(params, `${standard}${url}`, config)
    }
     // 人员
     static getListAPIStaff(params, url, config) {
        return http.get(params, `${staff}${url}`, config)
    }
     // 供应方
     static getListAPISupplier(params, url, config) {
        return http.get(params, `${supplier}${url}`, config)
    }
     // 资质
     static getListAPIQualification(params, url, config) {
        return http.get(params, `${qualification}${url}`, config)
    }
     // 质量活动-设备
     static getListAPIQualityActivityDevice(params, url, config) {
        return http.get(params, `${qualityActivityDevice}${url}`, config)
    }
     // 质量活动
     static getListAPIQualityActivity(params, url, config) {
        return http.get(params, `${qualityActivity}${url}`, config)
    }
     // 管理评审
     static getListAPIQualityAudit(params, url, config) {
        return http.get(params, `${qualityAudit}${url}`, config)
    }
  
    //==========↑==========↑==========↑==========↑==========GET==========↓==========↓==========↓==========↓==========

    //==========↓==========↓==========↓==========↓==========POST==========↓==========↓==========↓==========↓==========
    static postFormAPI(params, url, urlData, config) {
        return http.post(params, `${ base }${url}`, urlData, config)
    }
    static postFormAPISso(params, url, urlData, config) {
        return http.post(params, `${sso}${url}`, urlData,  config)
    }
    static postFormAPItenant(params, url, urlData, config) {
        return http.post(params, `${tenant }${url}`, urlData, config)
    }
    static postFormAPIResource(params, url, urlData, config) {
        return http.post(params, `${resourceBaseUrl }${url}`, urlData, config)
    }
    static postFormAPITemplate(params, url, urlData, config) {
        return http.post(params, `${template }${url}`, urlData, config)
    }
    static postFormAPIConnect(params, url, urlData, config) {
        return http.post(params, `${connect }${url}`, urlData, config)
    }
    // 质量检测
  // 部门
   static postFormAPILab(params, url,urlData, config) {
    return http.post(params, `${lab}${url}`,urlData, config)
  }
    // 设备
       static postFormAPIDevice(params, url, config) {
        return http.post(params, `${equip}${url}`, config)
     }
     //标准
       static postFormAPIStandard(params, url, config) {
        return http.post(params, `${standard}${url}`, config)
     }
     //人员
       static postFormAPIStaff(params, url, config) {
        return http.post(params, `${staff}${url}`, config)
     }
    //  供应方
       static postFormAPISupplier(params, url,urlData, config) {
        return http.post(params, `${supplier}${url}`,urlData, config)
     }
    //  资质
       static postFormAPIQualification(params, url, config) {
        return http.post(params, `${qualification}${url}`, config)
     }
    //  质量活动-设备
       static postFormAPIQualityActivityDevice(params, url,urlData, config) {
        return http.post(params, `${qualityActivityDevice}${url}`,urlData, config)
     }
    //  质量活动
       static postFormAPIQualityActivity(params, url,urlData, config) {
        return http.post(params, `${qualityActivity}${url}`,urlData, config)
     }
    // 管理评审
       static postFormAPIQualityAudit(params, url,urlData, config) {
        return http.post(params, `${qualityAudit}${url}`,urlData, config)
     }

    //==========↑==========↑==========↑==========↑==========POST==========↓==========↓==========↓==========↓==========

    //==========↓==========↓==========↓==========↓==========PUT==========↓==========↓==========↓==========↓==========

    static putFormAPItenant(params, url, urlData, config) {
        return http.put(params, `${tenant}${url}`, urlData, config)
    }

}

export {
    tenant 
}