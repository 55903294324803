<!--
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-15 09:13:17
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-11-13 11:03:50
 * @FilePath: \product-equip-view\src\views\standardManagement\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :id="preCls" :class="preCls">
    <PageWrapper :loading="tableLoading"  v-bind="getPageWrapperAttrs" :data="tableData"
      :pagination="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      @selection-change="handleSelectionChange" @row-click="rowClick" @right-tab-click="handleTabClick" @search="handleSearch"
      @add="handleAdd" @standard-check="handleOpenStandardCheckDialog" @export="handleBatchExportCheck">
      <template slot="action" slot-scope="scope">
        <el-button size="small" type="text" @click.stop="handleEditStandard(scope.row)">编辑</el-button>
        <el-button size="small" type="text" @click.stop="handleDeleteStandard(scope.row)">删除</el-button>
        <el-dropdown placement="bottom-start" trigger="hover" @command="(val) => handleActionMore(scope, val)" :hide-on-click="false">
          <el-button  type="text" size="small">
                        <!-- 更多
            <i class="el-icon-arrow-down el-icon--right"></i> -->
            <more style="width:16px;height:16px"></more>
          </el-button>
          <el-dropdown-menu slot="dropdown" class="table-action-dropdown">
            <el-dropdown-item>
              <img src="../assets/images/zt.png" alt="" />
              标准状态：
              <span class="running" v-show="scope.row.standardState == '01'">现行</span>
              <span class="replaced" v-show="scope.row.standardState == '02'">被替代</span>
              <span style="color: #d7d7d7" v-show="scope.row.standardState == '03'">废止</span>
              <span style="color: orange" v-show="scope.row.standardState == '04'">待实施</span>
            </el-dropdown-item>
            <el-dropdown-item v-for="(item, index) in standardStateOptions" :command="item.value" :key="index">
              <div class="pl-20">{{ item.label }}</div>
            </el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>

      </template>
      <template slot="tab-base-standardTypeAndState" >
        <div class="exclude">
          <button v-if="standardType" class="my-tag" style="background-color: rgba(44, 144, 206, 1);">
            {{ standardType }}
          </button>
          <button class="my-tag" v-if="currentStandardTypeItem?.label"
            :style="{ backgroundColor: currentStandardTypeItem?.color }">
            {{ currentStandardTypeItem?.label }}
          </button>
        </div>
      </template>
      <template slot="tab-base-standardTitle">
        {{ currentStandardName }}
      </template>
      <!-- 基础信息 文件 -->
      <template slot="tab-base-fileName-append" v-if="this.detailsData?.fileName">
        <el-button size=mini @click="handleDownloadStandardFile">下载</el-button>
      </template>
      <!-- 查新记录表格 -->
      <template slot="tab-noveltyRecord-table">
        <BasicTable v-bind="getBasicTableAttrs"  :data="standardCheckTableData"
          @size-change="handleStandardCheckSizeChange" @current-change="handleStandardCheckCurrentChange">
          <template slot="action" slot-scope="scope">
            <span class="btn_hover ml-0" @click="handleDeleteStandardCheck(scope.row)">删除</span>
          </template>
        </BasicTable>
      </template>
    <!-- 验证记录表格 -->
    <template slot="tab-verificationRecord-verificationTable">
        <BasicTable v-bind="getVerificationTableAttrs"  :data="standardVerificationTableData"
          @size-change="handleStandardVerificationSizeChange" @current-change="handleStandardVerificationCurrentChange">
          <template slot="action" slot-scope="scope">
            <span class="btn_hover ml-0" @click="handleDownloadStandardVerification(scope.row)">下载</span>
            <span class="btn_hover ml-0" @click="handleDeleteStandardVerification(scope.row)">删除</span>
          </template>
        </BasicTable>
      </template>

    </PageWrapper>
    <!-- 标准查新对话框 -->
    <BasicDialog width="600px" :visible="standardCheckdialogVisible" title="标准查新" :formConfig="standardCheckFormConfig"
      :model="standardCheckFormData" @confirm="handleStandardCheckDialogConfirm"
      @close="handleStandardCheckDialogClose">
    </BasicDialog>
    <!--标准抽屉  -->
    <BasicDrawer :visible="standardDrawerVisible" :title="standardDrawerTitle" :formConfig="standardFormConfig"
      :model="standardFormData" @confirm="handleStandardDrawerConfirm" @close="handleStandardDrawerClose">
    </BasicDrawer>
       <!-- 验证记录抽屉 -->
       <BasicDrawer :visible="verificationRecordDrawerVisible" title="新建 验证记录" :formConfig="verificationRecordFormConfig" :model="verificationRecordFormData"
      @confirm="handleVerificationRecordDrawerConfirm" @close="handleVerificationRecordDraweClose">
    </BasicDrawer>
    
  </div>
</template>

<script>
import { useForm, useTable, usePageWrapper } from '@/core/index';
import { standardTypeOptions, standardStateOptions, isStandardOptions } from '../dictionary'
import { downloadFile } from '@/utils/utils'
export default {
  name: 'standardManagement',
  data() {
    return {
      standardStateOptions,
      tableLoading: false,
      preCls: 'standard',
      queryData: '',
      // 表格数据 以及 列项
      tableData: [],
      currentStandardId: '', //当前标准id
      detailsData: {}, //详情数据
      selctionStandard: [],
      pagination: {
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      standardCheckdialogVisible: false, //是否显示标准查新对话框
      standardCheckFormData: {
        validity: ''
      },
      standardCheckTableLoading: false,
      standardCheckTableData: [], // 标准查新表格
      standardCheckPagiantion: {
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      verificationRecordDrawerVisible:false,   //是否显示标准查新对话框
      verificationRecordFormData:{
        standardVerificationFile:[], //验证记录文件
        standardId:'', //id
      }, 
      standardVerificationPagiantion: {
                pageNum: 1,
                pageSize: 15,
                total: 0,
            },
     standardVerificationLoading:false,
   standardVerificationTableData: [],  //验证记录表格
      currentStandardName: '', //标准名称
      currentStandardTypeItem: {}, //当前标准状态
      // 业务表单
      /** 新建/编辑 drawer显示/隐藏 */
      drawerDeviceVisible: false,
      //标准统计数据
      statisticsModel: {
        gb: '0',
        hb: '0',
        db: '0',
        tb: '0',
        qb: '0',
        gjb: '0'
      },
      /** 新建/编辑 标准信息drawer显示/隐藏 */
      standardDrawerVisible: false,
      //  标准信息表单
      standardFormData: {
        standardType: "", //标准类型
        standardNo: "", //标准编号
        standardNoEn: '', //标准编号（英文）
        standardName: "", //标准名称
        standardEnName: "", //标准名称-英文
        publishDate: "", //发布日期
        implementDate: "", //实施日期
        abolishDate: "",  //废止日期
        belongUnit: "", //归口单位
        performUnit: "", //执行单位
        directUnit: "", //主管单位
        // orgName: "", //所属部门展示的名字
        // orgId: "", //所属部门
        standardState: "", //标准状态
        isStandard: '',
        replaceDate: "", //被替代日期
        standardListFiles: [],//标准附件
      },
      standardType: "",
    };
  },
  computed: {
    designDrawerPreCls() {
      return `${this.preCls}DesignDrawer`;
    },
    /** PageWrapper属性 */
    getPageWrapperAttrs() {
      return usePageWrapper({
        pageHeaderConfig: {
          leftBtns: [
            {
              text: '新建',
              type: 'Add',
              emitEvent: 'add'
            },
            {
              text: '导出查新记录',
              type: 'Export',
              emitEvent: 'export',
            },
            {
              text: '标准查新',
              emitEvent: 'standard-check'
            },

          ],
        },
        statisticsConfig: {
          title: this.$route.meta.title,
          columns: [{
            label: '国家标准',
            prop: 'gb',
            style: {
              color: '#0101bf'
            }
          },
          {
            label: '行业标准',
            prop: 'hb',
            style: {
              color: '#009621'
            }
          },
          {
            label: '地方标准',
            prop: 'db',
            style: {
              color: '#d9011c'
            }
          },
          {
            label: '团体标准',
            prop: 'tb',
            style: {
              color: '#565656'
            }
          },
          {
            label: '企业标准',
            prop: 'qb',
            style: {
              color: '#f68909'
            }
          },
          {
            label: '国际标准',
            prop: 'gjb',
            style: {
              color: '#f68909'
            }
          },
          ],
          model: this.statisticsModel,
        },
        tableConfig: {
          cellClassName: this.cellClassName,
          tableTabs: [
            // ...standardTypeOptions,
          ],
        },
        columns: [
          {
            type: "selection",
            width: '40'
          },
          {
            type: "index",
            label: "序号",
            width: '50',
          },
          {
            label: "标准编号",
            prop: "standardNo",
            minWidth: '160',
            showOverflowTooltip: true
          },
          {
            label: "标准名称",
            prop: "standardName",
            minWidth: '160',
            showOverflowTooltip: true
          },
          {
            label: "发布日期",
            prop: "publishDate",
            width: '120',
            showOverflowTooltip: true
          },
          {
            label: "实施日期",
            prop: "implementDate",
            width: '120',
            showOverflowTooltip: true
          },
          {
            label: "标准状态",
            prop: "standardState",
            width: '120',
            showOverflowTooltip: true,
            options: standardStateOptions
          },
          {
            label: "标准类型",
            prop: "standardType",
            width: '120',
            showOverflowTooltip: true,
            options: standardTypeOptions
          },
          {
            label: "标准方法",
            prop: "isStandard",
            width: '120',
            showOverflowTooltip: true,
            options: isStandardOptions
          },
          {
            label: "操作",
            prop: "action",
            width: '140',
            useDefaultHeader: false,
            fixed: 'right'
          },
        ],
        pageRightTabsConfig: {
          tabPanes: [
            {
              label: '基础信息',
              name: 'base',
              descriptionsConfig: {
                model: this.detailsData,
                items: [
                  {
                    prop: 'standardTypeAndState',
                    labelStyle: {
                      display: 'none',
                      // marginBottom:'15px',
                    },
                    contentStyle:{
                      // marginBottom:'15px',
                    }
                  },
                  {
                    prop: 'standardTitle',
                    labelStyle: {
                      display: 'none',
                      marginBottom:'20px',
                    },
                    contentStyle: {
                      fontSize: '16px',
                      color: '#303133',
                      fontWeight: 'bold',
                      marginBottom:'20px',
                    }
                  },
                  {
                    label: '标准编号',
                    prop: 'standardNo',
                  },
                  {
                    label: '发布日期',
                    prop: 'publishDate',
                  },
                  {
                    label: '实施日期',
                    prop: 'implementDate',
                  },
                  {
                    label: '附件',
                    prop: 'fileName',
                  },
                ],
              },
            },
            {
              label: '查新记录',
              name: 'noveltyRecord',
              class:'tab-table',
              descriptionsConfig: {
                items: [
                  {
                    prop: 'table',
                    labelStyle: {
                      display: 'none'
                    },
                  },
                ]
              },
            },
            {
              label: '验证记录',
              name: 'verificationRecord',
              class:'tab-table',
              descriptionsConfig: {
                // model: this.pageRightTabsConfigWorkExperienceModel,
                showExtraButton: !!this.currentStandardId,
                extraButtonText: '新建验证记录',
                extraButtonClick: this.handleVerificationRecordAdd,
                items: [
                  {
                    prop: 'verificationTable',
                    labelStyle: {
                      display: 'none'
                    },
                  },
                ]
              },
            },
          ],
        },
      });
    },
    //查新记录table属性
    getBasicTableAttrs() {
      return useTable({
        loading: this.standardCheckTableLoading,
        hideTableToolbar: true,
        hideTableSearch: true,
        pagination: {
          pageSize: this.standardCheckPagiantion.pageSize,
          pageNum: this.standardCheckPagiantion.pageNum,
          total: this.standardCheckPagiantion.total,
        },
        columns: [
          {
            type: "index",
            label: "序号",
            width: '50'
          },
          {
            label: "查新记录",
            prop: "checkRecord",
            showOverflowTooltip: false,
          },
          {
            label: "操作",
            prop: "action",
            width: '70',
            align: 'left',
            useDefaultHeader: false,
          },
        ],
      })
    },
    //验证记录table属性
    getVerificationTableAttrs() {
      return useTable({
        loading: this.standardVerificationLoading,
        hideTableToolbar: true,
        hideTableSearch: true,
        pagination: {
          pageSize: this.standardVerificationPagiantion.pageSize,
          pageNum: this.standardVerificationPagiantion.pageNum,
          total: this.standardVerificationPagiantion.total,
        },
        columns: [
          {
            type: "index",
            label: "序号",
            width: '50'
          },
          {
            label: "验证记录",
            prop: "fileName",
          },
          {
            label: "操作",
            prop: "action",
            width: '100',
            align: 'left',
            useDefaultHeader: false,
          },
        ],
      })
    },
    /** 新建 标准表单配置项 */
    standardFormConfig() {
      return useForm({
        labelWidth: '155px',
        // disabled: true,
        schemas: [
          // 标准类型
          {
            label: '标准类型',
            prop: 'standardType',
            component: 'Select',
            rules: [
              {
                required: true,
                message: '请选择标准类型',
              },
            ],
            componentProps: {
              placeholder: '请选择标准类型',
              options: standardTypeOptions,
              onChange: (...parmas) => {
                console.log('onChange', ...parmas);
              },
            },
          },
          // 标准编号
          {
            label: '标准编号',
            prop: 'standardNo',
            component: 'Input',
            // colProps:{
            //    span:10,
            //    offset:1,
            //    pull:1,
            // },
            rules: [
              {
                required: true,
                message: '请输入标准编号',
              },
            ],
            componentProps: {
              maxlength: 128,
              showWordLimit: true,
              placeholder: '请输入标准编号',
            },
          },
          // 标准编号（英文）
          {
            label: '标准编号（英文）',
            prop: 'standardNoEn',
            component: 'Input',
            componentProps: {
              maxlength: 128,
              showWordLimit: true,
              placeholder: '请输入标准编号（英文）',
            },
          },
          // 标准名称
          {
            label: '标准名称',
            prop: 'standardName',
            component: 'Input',
            rules: [
              {
                required: true,
                message: '请输入标准名称',
              },
            ],
            componentProps: {
              maxlength: 245,
              showWordLimit: true,
              placeholder: '请输入标准名称',
            },
          },
          // 标准英文名称
          {
            label: '英文名称',
            prop: 'standardEnName',
            component: 'Input',
            rules: [
              {
                required: true,
                message: '请输入英文名称',
              },
            ],
            componentProps: {
              maxlength: 245,
              showWordLimit: true,
              placeholder: '请输入英文名称',
            },
          },
          // 标准状态
          {
            label: '标准状态',
            prop: 'standardState',
            component: 'RadioGroup',
            rules: [
              {
                required: true,
                message: '请选择标准状态',
              },
            ],
            componentProps: {
              options: standardStateOptions,
            },
          },
          // 发布日期
          {
            label: '发布日期',
            prop: 'publishDate',
            component: 'DatePicker',

            componentProps: {
              type: 'date',
              placeholder: '选择日期',
              pickerOptions: this.pickerStartDateOptions,
              valueFormat: 'yyyy-MM-dd',
              style: {
                width: '100%'
              },
            },
          },
          // 实施日期
          {
            label: '实施日期',
            prop: 'implementDate',
            component: 'DatePicker',
            componentProps: {
              type: 'date',
              placeholder: '选择日期',
              pickerOptions: this.pickerEndDateOptions,
              valueFormat: 'yyyy-MM-dd',
              style: {
                width: '100%'
              },
            },
          },
          // 被替代日期
          {
            labelWidth: '155px',
            label: '被替代日期',
            ifShow: this.standardFormData.standardState == '02',
            prop: 'replaceDate',
            component: 'DatePicker',
            componentProps: {
              type: 'date',
              placeholder: '选择日期',
              valueFormat: 'yyyy-MM-dd',
              style: {
                width: '100%',

              },
            },
          },
          // 废止日期
          {
            label: '废止日期',
            prop: 'abolishDate',
            ifShow: this.standardFormData.standardState == '03',
            component: 'DatePicker',
            componentProps: {
              type: 'date',
              placeholder: '选择日期',
              valueFormat: 'yyyy-MM-dd',
              style: {
                width: '100%',

              },
            },
          },
          // 归口单位
          {
            label: '归口单位',
            prop: 'belongUnit',
            component: 'Input',
            componentProps: {
              maxlength: 128,
              showWordLimit: true,
              placeholder: '请输入归口单位',
            },
          },
          // 执行单位
          {
            label: '执行单位',
            prop: 'performUnit',
            component: 'Input',
            componentProps: {
              maxlength: 128,
              showWordLimit: true,
              placeholder: '请输入执行单位',
            },
          },
          // 执行单位
          {
            label: '主管单位',
            prop: 'directUnit',
            component: 'Input',
            componentProps: {
              maxlength: 128,
              showWordLimit: true,
              placeholder: '请输入主管单位',
            },
          },
          // 标准附件
          {
            label: '标准附件',
            prop: 'standardListFiles',
            component: 'Upload',
            colProps: {
              span: 24,
            },
            rules: [
              {
                required: false,
                message: '请上传文件',
              },
            ],
            componentProps: {
              multiple: false,
              limit: 1,
              accept: '.doc, .docx, .pdf, .xls, .xlsx, .jpg, .png, .webp, .gif',
              beforeUpload: (file) => {
                let reg = /(.docx?|.pdf|.xlsx?|.jpg|.png|.webp|.gif)$/;
                if (!reg.test(file.name)) {
                  this.$message({
                    type: 'warning',
                    message: '只支持上传doc、docx、pdf、xls、xlsx、jpg、png、webp、gif格式'
                  });
                };
                return reg.test(file.name)
              },
              beforeRemove: (params, fileList) => {
                this.standardFormData.standardListFiles = fileList;
                console.log('beforeRemove', params, fileList);
              },
              onExceed: () => {
                this.$message({
                  message: "只支持上传一个标准文件!",
                  type: "warning",
                });
              },
            },
          },

        ],


      });
    },
    pickerStartDateOptions()
    {
      let that =this;
      return {
        disabledDate(time) {
          if(that.standardFormData.implementDate)
        {
          return time.getTime() > new Date(that.standardFormData.implementDate).getTime()
        }   
        }
      }
    },
    pickerEndDateOptions()
    {
      let that =this;
      return {
        disabledDate(time) {
          if(that.standardFormData.publishDate)
        {
          return time.getTime() < new Date(that.standardFormData.publishDate).getTime() - 8.64e7
        }
         
        }
      }
    },
    /** 标准查新 表单配置项 */
    standardCheckFormConfig() {
      return useForm({
        labelWidth: '180px',
        // disabled: true,
        hideRequiredAsterisk:true,
        schemas: [
          {
            labelWidth: '0',
            prop: 'validity',
            component: 'RadioGroup',
            colProps: {
              span: 8,
              offset: 8,
            },
            rules: [
              {
                required: true,
                message: '请选择有效性',
              },
            ],
            componentProps: {
              options: [
                {
                  label: '现行有效',
                  value: '01'
                },
                {
                  label: '无效',
                  value: '02'
                }
              ],
            },
          },
        ],

      });
    },
    /** 新建/编辑 标准抽屉标题 */
    standardDrawerTitle() {
      return this.standardFormData.id ? '编辑 标准信息' : '新建 标准信息';
    },
      /** 新建 验证记录表单配置项 */
      verificationRecordFormConfig() {
      return useForm({
        labelWidth: '180px',
        ref: 'verificationRecordRef',
        // disabled: true,
        schemas: [
          // 上传文件
          {
            prop: 'standardVerificationFile',
            labelWidth: '0px',
            component: 'Upload',
            colProps: {
              span: 24,
            },
            rules: [
              {
                required: false,
                message: '请上传文件',
              },
            ],
            componentProps: {
              multiple: false,
              limit: 1,
              accept: '.doc, .docx, .pdf, .xls, .xlsx, .jpg, .png, .webp, .gif',
              beforeRemove: (params, fileList) => {
                this.verificationRecordFormData.standardVerificationFile = fileList;
              },
              beforeUpload: (file) => {
                let reg = /(.docx?|.pdf|.xlsx?|.jpg|.png|.webp|.gif)$/;
                if (!reg.test(file.name)) {
                  this.$message({
                    type: 'warning',
                    message: '只支持上传doc、docx、pdf、xls、xlsx、jpg、png、webp、gif格式'
                  });
                };
                return reg.test(file.name)

              },
              onExceed: () => {
                this.$message({
                  message: "只支持上传一个文件!",
                  type: "warning",
                });
              },
            },
          },

        ],

      });
    },
  },
  mounted() {
    this.getTableData();
    this.getStatisticsData();
  },

  methods: {

    /** 表格右侧搜索事件 */
    handleSearch(queryData) {
      this.pagination = {
        ...this.$options.data().pagination,
      };
      if (typeof queryData !== 'undefined') {
        this.queryData = queryData || '';
      }
      this.getTableData();
    },
    // 获取表格数据
    getTableData() {
      this.tableLoading = true;
      let data = {
        ...this.pagination,
        filters: [
          {
            fieldName: "state",
            operator: "EQ",
            connectOperator: "AND",
            fieldValue: "0",
          },
          {
            fieldName: "standardName",
            operator: "LIKE",
            connectOperator: "AND",
            fieldValue: this.queryData,
            groupName: "_s",
          },
          {
            fieldName: "standardNo",
            operator: 'LIKE',
            connectOperator: 'OR',
            fieldValue: this.queryData,
            groupName: "_s",
          },
        ],
        sorters: [
          {
            sortField: "createTime",
            direction: "DESC",
          },

        ],

      };
      this.api.postFormAPIStandard(
        data,
        "queryStandard"
      ).then(async res => {
        this.tableLoading = false;
        this.tableData = res.data.data.records || [];
        this.pagination.total = res.data.data.total;
        this.detailsData ={};
        this.standardCheckTableData = [];
        this.standardVerificationTableData = [];
       
      }).catch(error => {
        console.log('error', error);
      })

    },
    // 获取统计数据
    async getStatisticsData() {
      const res = await this.api.postFormAPIStandard(
        '',
        'getStandardStatistics'
      )
      this.statisticsModel = res.data.data;
    },
    handleTabClick(tab, name) {
     if(name=='noveltyRecord')
     {
        this.getStandardCheckTableData(this.currentStandardId);
     }
     else if(name=='verificationRecord')
     {
      this.getStandardVerificationTableData();
     }
    },
    handleSizeChange(pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.pageNum = 1;
      this.getTableData() //刷新页面
    },
    //首页表格第几页
    handleCurrentChange(pageNum) {
      this.pagination.pageNum = pageNum
      this.getTableData() //刷新页面
    },
    handleSelectionChange(selection) {
      console.log('handleSelectionChange', selection.length);
      this.selctionStandard = selection;
    },
    //行点击
    rowClick(row) {
        this.currentStandardId = row?.id;
        if(this.currentStandardId){
          this.currentStandardName = row?.standardName;
        this.getDetailInfo( this.currentStandardId );
        this.getStandardCheckTableData( this.currentStandardId );
        this.getStandardVerificationTableData();
        }

    },

    //新增
    handleAdd() {
      this.standardDrawerVisible = true;

    },
    /**
     * @param { { id: string } } row
     * @description 编辑标准
     * */
    async handleEditStandard(row) {
      let loading = this.$loading();
      let { creator, createTime,fileName,fileType,fileUrl, status, state, updateTime, updator, modelAttrs, ...obj } = await this.getDetailInfo(row.id);
      loading.close();
      this.standardFormData = {
         ...obj,
         standardListFiles: fileName ? [{
            name: fileName,
            file: { name: fileName }
          }] : []
         };
      this.standardDrawerVisible = true;
    },
    //获取详情
    async getDetailInfo(standardId) {
      let res = await this.api.getListAPIStandard(
        { standardId },
        'getStandard'
      )

      this.detailsData = res.data.data;
      this.standardType = standardTypeOptions.find(item => item.value == this.detailsData.standardType).label;
      this.currentStandardTypeItem = standardStateOptions.find(item => item.value == this.detailsData.standardState);
      return res.data.data;
    },
        //删除标准
        async handleDeleteStandard(row) {
      let valid = await this.confirmPromise();
      if (!valid) return;
      let loading = this.$loading();
      this.api
        .getListAPIStandard(
          {
            standardId: row.id
          },
          "deleteStandard",
        )
        .then((res) => {
          loading.close();
          this.handleSearch();
          this.getStatisticsData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });

        })
    },
    handleActionMore({ row }, standardState) {
      if (standardState) {
        this.api.getListAPIStandard(
          {
            standardId: row.id,
            standardState
          },
          "modifyStandardState"
        )
          .then((res) => {
            this.handleSearch();
            this.getStatisticsData();
            this.$message({
              message: '操作成功',
              type: "success",
            });
          })
          .catch((err) => console.log(err));
      }
    },
    // 导出标准查新
    handleBatchExportCheck() {
      if (!this.selctionStandard.length) {
        return this.$message({
          type: 'warning',
          message: '请选择标准'
        });
      }
      const selectedIds = this.selctionStandard.map(item => item.id);
      this.api.postFormAPIStandard(
        selectedIds,
        'exportStandardCheck',
        '',
        {
          responseType: 'blob'
        }
      ).then(res => {
        console.log('res.data', res.data);
        downloadFile('标准查新记录.docx', res.data, '', '');
      })
    },
    // 打开查新dialog
    handleOpenStandardCheckDialog() {
      if (!this.selctionStandard.length)
        return this.$message({
          type: 'warning',
          message: '请选择标准'
        });
      this.standardCheckdialogVisible = true;

    },
    //标准查新确认
    handleStandardCheckDialogConfirm() {
      const selectedIds = this.selctionStandard.map(item => item.id);
      let loading = this.$loading();
      this.api.postFormAPIStandard(
        selectedIds,
        'saveStandardCheck',
        {
          validity: this.standardCheckFormData.validity,
        }
      ).then(res => {
        loading.close();
        this.$message({
          type: 'success',
          message: '操作成功'
        });
        this.handleStandardCheckDialogClose();
        this.getStandardCheckTableData(this.currentStandardId);
      }).catch(error => {
        console.log('error', error);
      })
    },
    //标准查新取消
    handleStandardCheckDialogClose() {
      this.standardCheckdialogVisible = false;
      this.standardCheckFormData = {
        ...this.$options.data().standardCheckFormData,
      };

    },
    //标准表单确认
    handleStandardDrawerConfirm() {
      let loading = this.$loading();
      let formdata = new FormData();
  this.standardFormData.standardListFiles.length > 0 && this.standardFormData.standardListFiles[0].file instanceof File  && formdata.append("file", this.standardFormData.standardListFiles[0].file);
      if (this.standardFormData.id) {
        const { standardListFiles, ...standardFormDataObj } = this.standardFormData;
        this.standardFormData = standardFormDataObj;
      }
      formdata.append(
        "standard",
        new Blob([JSON.stringify({
          ...this.standardFormData,
          isStandard: this.standardFormData.standardType === '99' ? '02' : '01',
        })], { type: "application/json" })
      );
      formdata.append(
        "standardId",
        this.standardFormData.id
      )
      this.api.postFormAPIStandard(
        formdata,
        this.standardFormData.id ? 'modifyStandard' : 'saveStandard',
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
          this.handleStandardDrawerClose();
          this.handleSearch();
          this.getStatisticsData();
        })
        .catch(error => {
          console.log('error', error);
        }).finally(() => {
          loading.close();
        })
    },
    //标准表单取消
    handleStandardDrawerClose() {
      this.standardDrawerVisible = false;
      this.standardFormData = {
        ...this.$options.data().standardFormData,
        standardListFiles: []
      }

    },
    // 标准查新表格记录
    getStandardCheckTableData(id) {
      this.standardCheckTableLoading = true;
      // /v1.0/standard/queryStandardCheck
      this.api.postFormAPIStandard(
        {
          ...this.standardCheckPagiantion,
          filters: [
            {
              fieldName: 'standardCheck.standardId',
              operator: 'EQ',
              fieldValue: id,
            }
          ],
          sorters: [
          {
            sortField: "createTime",
            direction: "DESC",
          },
        ],
        },
        'queryStandardCheck'
      ).then(res => {
        this.standardCheckTableLoading = false;
        this.standardCheckTableData = res.data.data.records;
        this.standardCheckPagiantion.total = res.data.data.total;
      }).catch(error => {
        this.standardCheckTableLoading = false;
        console.log('error', error);
      })
    },
    // //标准查新表格每页显示条数
    handleStandardCheckSizeChange(pageSize) {
      this.standardCheckPagiantion.pageSize = pageSize
      this.standardCheckPagiantion.pageNum = 1;
      this.getStandardCheckTableData(this.currentStandardId) //刷新页面
    },
    //标准查新表格页码切换
    handleStandardCheckCurrentChange(pageNum) {
      this.standardCheckPagiantion.pageNum = pageNum
      this.getStandardCheckTableData(this.currentStandardId) //刷新页面
    },

    //删除查新表格记录
    async handleDeleteStandardCheck(row) {
      let valid = await this.confirmPromise();
      if (!valid) return;
      let loading = this.$loading();
      this.api
        .getListAPIStandard(
          {
            standardCheckId: row.id
          },
          "deleteStandardCheck",
        )
        .then((res) => {
          loading.close();
          this.getStandardCheckTableData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });

        })
    },
    //查看基本信息文件
    handleViewStandardFile() {
    },
    //下载基本信息文件
    handleDownloadStandardFile() {
      this.api.getListAPIStandard(
        {
          standardId: this.detailsData.id,
        },
        'downloadStandardFile'
      )
        .then(res => {
          downloadFile(this.detailsData.fileName, '', '', res.data.data);
        })
        .catch(error => {
          console.log('error', error);
        })
    },
    // 新建验证记录
    handleVerificationRecordAdd()
    {
      this.verificationRecordDrawerVisible = true;

    },
    //验证记录确认
    handleVerificationRecordDrawerConfirm()
    {
      if (!this.verificationRecordFormData.standardVerificationFile.length) {
        return this.$message({
          type: 'warning',
          message: '请先上传文件'
        });
      }
      this.verificationRecordFormData.standardId = this.currentStandardId;
      let loading = this.$loading();
      let data = new FormData();
      data.append('standardId', this.verificationRecordFormData.standardId);
      data.append('file', this.verificationRecordFormData.standardVerificationFile[0].file);
      this.api.postFormAPIStandard(
        data,
        'saveStandardVerification'
      ).then(() => {
        loading.close();
        this.$message({
          type: 'success',
          message: '上传成功',
        });
        this.handleVerificationRecordDraweClose();
        this.getStandardVerificationTableData();
      }).catch(error => {
        console.log('error', error);
      })
    },
    //验证记录取消
    handleVerificationRecordDraweClose()
    {
      this.verificationRecordFormData.standardVerificationFile = [];
        this.verificationRecordDrawerVisible = false;
    },
     // 验证记录表格
     getStandardVerificationTableData() {
           this.standardVerificationLoading = true;
            this.api.postFormAPIStandard(
                {
                        ...this.standardVerificationPagiantion,
                        filters: [
                            {
                                fieldName: 'standardId',
                                operator: 'EQ',
                                fieldValue: this.currentStandardId,
                            }
                        ]

                },
                'queryStandardVerification'
            ).then(res => {
              this.standardVerificationLoading = false;
                this.standardVerificationTableData = res.data.data.records || [];
                this.standardVerificationPagiantion.total = res.data.data.total;
            }).catch(error => {
                console.log('error', error);
            })
        },
        //下载验证表格每行记录
        handleDownloadStandardVerification(row)
        {
          this.api.postFormAPIStandard(
                '',
                'downloadStandardVerification',
                {
                    standardVerificationId: row.id
                },
            ).then(res => {
              downloadFile(row.fileName, '', '', res.data.data);
            }).catch(error => {
                console.log('error', error);
            })
        },
        //删除验证表格每行记录
       async handleDeleteStandardVerification(row)
        {
          let valid = await this.confirmPromise();
      if (!valid) return;
      let loading = this.$loading();
      this.api
        .getListAPIStandard(
          {
            standardVerificationId: row.id
          },
          "deleteStandardVerification",
        )
        .then((res) => {
          loading.close();
          this.getStandardVerificationTableData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });

        })
        },
     handleStandardVerificationSizeChange(pageSize)
     {
      this.standardVerificationPagiantion.pageSize = pageSize
      this.standardVerificationPagiantion.pageNum = 1;
       this.getStandardVerificationTableData();
    },
     handleStandardVerificationCurrentChange(pageNum)
     {
      this.standardVerificationPagiantion.pageNum = pageNum
      this.getStandardVerificationTableData() //刷新页面
     }

  },
};
</script>
<style lang="less" scoped>
.standard {
  height: 100%;

}
</style>