// 使用 require.context 动态导入组件
const requireComponent = require.context(
	// 组件目录的相对路径
	'./icon/',
	// 是否查询其子目录
	false,
	// 匹配基础组件文件名的正则表达式
	/\w+\.(svg)$/
);
// console.log('requireComponent', requireComponent);
const components = {};

let fileNames = requireComponent.keys();
// console.log('fileNames', fileNames);
let reg = /\/?([\w-_]+)\.svg/i;
for (let i = 0 ; i < fileNames.length; i++){
	if (!reg.test(fileNames[i])){
		// console.warn(`图标命名不规范-${ fileNames[i] }`);
		continue;
	}
	let componentName = reg.exec(fileNames[i])[1];
	// let componentName = fileNames[i].replace(/^\.\/(.*)\.\w+$/, '$1');
	// console.log('componentName', componentName);
	let componentConfig = requireComponent(fileNames[i]);
	console.log('components',componentName);
	if (!components[componentName]){
		components[componentName] = componentConfig.default || componentConfig;
	} else {
		// console.warn(`图标组件已存在-${ componentName }`);
	}
	
}


export default components;
