/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-10-24 17:31:55
 * @FilePath: \product-equip-view\src\router\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-09-03 11:22:27
 * @FilePath: \product-equip-view\src\router\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-08-30 17:05:26
 * @FilePath: \product-equip-view\src\router\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-08-28 09:20:04
 * @FilePath: \product-equip-view\src\router\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

/** 首页 */
const Home = () => import("@/views-dev/Home");
/** 示例 */
const Demo = () => import("@/views-dev/Demo");
/** 子应用 */
const Index = () => import("@/views/Index/index");


const equipment = () => import("@/views/EquipmentManagement/index.vue"); //设备
const standardManagement = () => import("@/views/standardManagement/index.vue"); //标准
const personelManagement = () => import("@/views/personelManagement/index.vue"); //人员
const provider = () => import("@/views/provider/index.vue"); //供应方
const subcontractor = () => import("@/views/subcontractor/index.vue"); //分包方
const labQualification = () => import("@/views/labQualification/index.vue"); //资质
const equipmentMaintenance = () => import("@/views/equipmentMaintenance/index.vue"); //设备维护
const intermediateChecks = () => import("@/views/intermediateChecks/index.vue"); //期间核查计划
const qualityControl = () => import("@/views/qualityControl/index.vue"); //质量控制计划
const powerComparison = () => import("@/views/powerComparison/index.vue"); //能力验证/比对计划
const staffTraining = () => import("@/views/staffTraining/index.vue"); //人员培训计划
const staffSupervise = () => import("@/views/staffSupervise/index.vue"); //人员监督计划
const qualityActivityPlan = () => import("@/views/qualityActivityPlan/index.vue"); //质量活动计划任务书
const detectionscheme = () => import("@/views/detectionscheme/index.vue"); //量值溯源计划
const qcImpleStatus = () => import("@/views/qualityControl/qcImpleStatus/index.vue"); //质量控制计划-执行情况
const powComImpleStatus = () => import("@/views/powerComparison/powComImpleStatus/index.vue"); //能力验证/比对计划-执行情况
const staffImpleStatus = () => import("@/views/staffTraining/staffImpleStatus/index.vue"); //人员培训计划-执行情况
const staffSupImpleStatus = () => import("@/views/staffSupervise/staffSupImpleStatus/index.vue"); //人员监督计划-执行情况
const keyPlaces = () => import("@/views/detectionCapabilityManage/keyPlaces/index.vue"); //关键场所
const objectCategory = () => import("@/views/detectionCapabilityManage/objectCategory/index.vue"); //对象类别
const detectionCapability = () => import("@/views/detectionCapabilityManage/detectionCapability/index.vue"); //检测能力
const formulationPlan = () => import("@/views/internalCheck/formulationPlan/index.vue"); //内部审核-计划编制
const receptionPlan = () => import("@/views/internalCheck/receptionPlan/index.vue"); //内部审核-计划执行
const reviewsManagement = () => import("@/views/reviewsManagement/index.vue"); //管理评审
// 资源管理
const resourceManagement =[
	{
		path: "/labQualification",
		name: "labQualification",
		component: labQualification,
		meta: {
			title: "实验室资质",
			groupName: '资源管理',
			icon: "labQualification",
			showInSideMenu:true,
		},
	},
{
	path: "/equipment",
	name: "equipment",
	component: equipment,
	meta: {
		title: "仪器设备",
		icon: "equipmentPage",
		showInSideMenu:true,
	},
},
{
	path: "/standard",
	name: "standardManagement",
	component: standardManagement,
	meta: {
		title: "标准规范",
		icon: "Standard",
		showInSideMenu:true,
	},
},
{
	path: "/personelManagement",
	name: "personelManagement",
	component: personelManagement,
	meta: {
		title: "检测人员",
		icon: "Person",
		showInSideMenu:true,
	},
},
{
	path: "/provider",
	name: "provider",
	component: provider,
	meta: {
		title: "供应方",
		icon: "provider",
		showInSideMenu:true,
	},
},
{
	path: "/subcontractor",
	name: "subcontractor",
	component: subcontractor,
	meta: {
		title: "分包方",
		icon: "subcontractor",
		showInSideMenu:true,
	},
},
]
//质量活动
const qualiatyActivity =[
	{
		path: "/qualityActivityPlan",
		name: "qualityActivityPlan",
		component: qualityActivityPlan,
		meta: {
			title: "质量活动计划任务书",
			icon: "qualityActivityPlan",
			groupName: '质量活动',
			showInSideMenu:true,
		},
	},
	{
		path: "/detectionscheme",
		name: "detectionscheme",
		component: detectionscheme,
		meta: {
			title: "量值溯源计划",
			icon: "detectionScheme",
			showInSideMenu:true,
		},
	},
	{
		path: "/equipmentMaintenance",
		name: "equipmentMaintenance",
		component: equipmentMaintenance,
		meta: {
			title: "设备维护计划",
			icon: "equipmentMaintenance",
			showInSideMenu:true,
		},
	},
	{
		path: "/intermediateChecks",
		name: "intermediateChecks",
		component: intermediateChecks,
		meta: {
			title: "期间核查计划",
			icon: "intermediateChecks",
			showInSideMenu:true,
		},
	},
	{
		path: "/qualityControl",
		name: "qualityControl",
		component: qualityControl,
		meta: {
			title: "质量控制计划",
			icon: "qualityControl",
			showInSideMenu:true,
		},
	},
	{
		path: "/powerComparison",
		name: "powerComparison",
		component: powerComparison,
		meta: {
			title: "能力验证/比对计划",
			icon: "powerComparison",
			showInSideMenu:true,
		},
	},
	{
		path: "/staffTraining",
		name: "staffTraining",
		component: staffTraining,
		meta: {
			title: "人员培训计划",
			icon: "staffTraining",
			showInSideMenu:true,
		},
	},
	{
		path: "/staffSupervise",
		name: "staffSupervise",
		component: staffSupervise,
		meta: {
			title: "人员监督计划",
			icon: "staffSupervise",
			showInSideMenu:true,
		},
	},
]
//检测能力
const detectionCapabilityManage =[
	{
		path: "/detectionCapability",
		name: "detectionCapability",
		component: detectionCapability,
		meta: {
			groupName: '检测能力',
			title: "检测能力",
			icon: "detectionCapability",
			showInSideMenu:true,
		},
	},
	{
		path: "/keyPlaces",
		name: "keyPlaces",
		component: keyPlaces,
		meta: {
			title: "关键场所",
			icon: "keyPlaces",
			showInSideMenu:true,
		},
	},
	{
		path: "/objectCategory",
		name: "objectCategory",
		component: objectCategory,
		meta: {
			title: "对象类别",
			icon: "objectCategory",
			showInSideMenu:true,
		},
	},
]
//内部审核
const internalCheck =[
	{
		path: "/formulationPlan",
		name: "formulationPlan",
		component: formulationPlan,
		meta: {
			groupName: '内部审核',
			title: "计划编制",
			icon: "formulationPlan",
			showInSideMenu:true,
		},
	},
	{
		path: "/receptionPlan",
		name: "receptionPlan",
		component: receptionPlan,
		meta: {
			title: "计划执行",
			icon: "receptionPlan",
			showInSideMenu:true,
		},
	},
]
//管理评审
const reviewsManage =[
	{
		path: "/reviewsManage",
		name: "reviewsManage",
		component: reviewsManagement,
		meta: {
			groupName: '管理评审',
			title: "管理评审",
			icon: "reviewsManage",
			showInSideMenu:true,
		},
	},
]
const detailRouters =[
	//质量控制计划-执行情况
	{
		path: "/qualityControl/qcImpleStatus",
		name: "qcImpleStatus",
		component: qcImpleStatus,
		meta: {
			title: "执行情况",
			showInSideMenu:false,
		},
	},
	//能力验证/比对计划-执行情况
	{
		path: "/powerComparison/powComImpleStatus",
		name: "powComImpleStatus",
		component: powComImpleStatus,
		meta: {
			title: "执行情况",
			showInSideMenu:false,
		},
	},
	//人员培训计划-执行情况
	{
		path: "/staffTraining/staffImpleStatus",
		name: "staffImpleStatus",
		component: staffImpleStatus,
		meta: {
			title: "执行情况",
			showInSideMenu:false,
		},
	},
	//人员监督计划-执行情况
	{
		path: "/staffSupervise/staffSupImpleStatus",
		name: "staffSupImpleStatus",
		component: staffSupImpleStatus,
		meta: {
			title: "执行情况",
			showInSideMenu:false,
		},
	},
]
/** layout固定路由表 */
let constantLayoutRoutes = [
	{
		path: "/home",
		name: "Home",
		component: Home,
		meta: {
			title: "首页",
			groupName: '工作台',
			icon: "HomePage",
			showInSideMenu:true,
		},
	},
...resourceManagement,	
...qualiatyActivity,
...detectionCapabilityManage,
...internalCheck,
...reviewsManage
	// {
	// 	path: "/demo",
	// 	name: "Demo",
	// 	component: Demo,
	// 	meta: {
	// 		title: "示例",
	// 		groupName: '示例',
	// 		icon: "HomePage",
	// 	},
	// },
	// {
	// 	path: "/index",
	// 	name: "Index",
	// 	component: Index,
	// 	meta: {
	// 		title: "子应用",
	// 		groupName: '子应用',
	// 		icon: "HomePage",
	// 	},
	// },
]
const asyncRouters =[];
const routes = [];

const addRoute = route => {

}
// constantLayoutRoutes =constantLayoutRoutes.filter(item => item.meta.showInSideMenu)
export {
	constantLayoutRoutes,
	routes,
	detailRouters,
	addRoute,
}