/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-10-18 10:32:08
 * @FilePath: \product-equip-view\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import './views/style/common.less'
import api from '../src/api/api.js'
import dayjs from "dayjs";
import core from '@/core/index';
import rsa from '@/utils/rsa'
// import { setLocalToken } from "./core/token";
// setLocalToken('labbol eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiI0RkM0MEEyOEI5ODQ0MkNCQjM4NTVCNkFBMkM2MDZEMyIsInJuU3RyIjoiYnhRQThRVFdCVmdlRloyVDZNVDJjWERpaFFRaXJqT3AiLCJ1c2VySWQiOiI0RkM0MEEyOEI5ODQ0MkNCQjM4NTVCNkFBMkM2MDZEMyIsInRlbmFudElkIjoiQ0I0M0I1MjM0MkZENERFNjlENTA5QTdFQjc4QUEwODciLCJ1c2VyTmFtZSI6IjE3NzY0MDg1Nzg0Iiwib3JnSWQiOiI4RTQ5MEQ3NzI0QTA0NjgxQjJBQ0VDNkRFNTVDNUE3MSJ9.9ItO8j9I9Qm3DPPMafAfrFexbHvvxNXu9W8quSSTnDM')

Vue.use(ElementUI);
Vue.prototype.api = api;
Vue.prototype.dayjs =dayjs;
Vue.config.productionTip = false
Vue.prototype.Rsa = rsa;
import mixin from '@/core/mixin';
Vue.mixin(mixin);
Vue.use(core);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")
