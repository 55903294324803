/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-11-12 18:12:36
 * @FilePath: \product-equip-view\src\core\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import Vue from 'vue';
// electron运行环境下不支持Promise.finally 引入core-js来支持Promise.finally写法
import 'core-js/features/promise/finally';
// import '@/core/Electron';

import LabbolBasicComponents from 'labbol-basic-components';
// import LabbolBasicComponents from '../../../labbol-basic-components';
import globalComponent from '@/views/index';
import $ChoiceTree from "@/components/ChoiceTree/index.js";
import $choicePersonnel from "@/components/ChoicePersonnel/index.js";
import $choicePersonnelByRoleGroup from "@/components/choicePersonnelByRoleGroup/index.js";
import $choiceStaff from "@/components/ChoiceStaff/index.js";
import $choiceDevice from '@/components/ChoiceDevice/index.js';
import $choiceKeyPlace from '@/components/ChoiceKeyPlace/index.js'
import $choiceStandard from '@/components/ChoiceStandard/index.js'
import $choiceUploadFiles from '@/components/ChoiceUploadFiles/index.js';
export default {
	install(Vue) {
		Vue.use(LabbolBasicComponents);
		Vue.use(globalComponent);
		Vue.use($ChoiceTree);
		Vue.use($choicePersonnel);
		Vue.use($choicePersonnelByRoleGroup);
		Vue.use($choiceStaff);
		Vue.use($choiceDevice);
		Vue.use($choiceKeyPlace);
		Vue.use($choiceStandard);
		Vue.use($choiceUploadFiles);

	}
}


import {setUiConfig, ChangePassword, choicePersonnelByRoleGroup, choicePersonnelByOrgRole, 
	useDescriptions, useForm, usePageWrapper, useTable,
	LoginPath, LoginName, Login,
RegisterPath, RegisterName, Register,
ChangePasswordPath,ChangePasswordName,
ToolbarUser, ToolbarSubscribeApps,
 PATTERNTYPESENUM, PATTERNSENUM,SvgComponentNames } from 'labbol-basic-components';
// import {setUiConfig, ChangePassword, choicePersonnelByRoleGroup, choicePersonnelByOrgRole, 
// 	useDescriptions, useForm, usePageWrapper, useTable,
// 	LoginPath, LoginName, Login,
// RegisterPath, RegisterName, Register,
// ChangePasswordPath,ChangePasswordName,
// ToolbarUser, ToolbarSubscribeApps,
//  PATTERNTYPESENUM, PATTERNSENUM,SvgComponentNames } from '../../../labbol-basic-components';
setUiConfig({
	QUERY_TREE_URL:'/v1.0/lab/common/select/getIscOrgTree',
	QUERY_ROLES_URL:'/v1.0/lab/common/select/queryRoleList',
	QUERY_USERS_URL:'/v1.0/lab/common/select/getIscUsers',
	GET_USER_BY_ROLEID_URL:'/v1.0/lab/common/select/getUserByRoleId'
})
export {
	ChangePassword, choicePersonnelByRoleGroup, choicePersonnelByOrgRole, 
	useForm, useDescriptions, usePageWrapper, useTable, 
	LoginPath, LoginName, Login,
	RegisterPath, RegisterName, Register,
	ChangePasswordPath,ChangePasswordName,
	ToolbarUser, ToolbarSubscribeApps,
	PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames
}
