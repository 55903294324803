<template>
    <el-dialog class="my-dialog keyPlace" :title="title"  :visible.sync="visible" width="70%"
        :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="false" @close="handleClose"
    >
        <div class="search-wrapper custom-input">
            <el-input size="small" v-model="queryData" placeholder="请输入地址代码或地址"
                @keyup.enter.native="handleSearch"></el-input>
            <el-button style="background:#0F7C4F" type="primary" size="small" @click="handleSearch">查询</el-button>
        </div>

        <div class="table-body">
            <el-table ref="table_ref" :data="tableData" border v-loading="table_loading"
                highlight-current-row @select="handleSelectDeviceRow"
                @row-click="handleClickDeviceRow"
                :header-cell-style="{ background: 'rgb(248, 248, 248)', color: 'rgb(51, 51, 51)' }" :row-style="rowClass"
            >
                <el-table-column show-overflow-tooltip type="selection" width="45" label-class-name="single-selected">
                </el-table-column>
                <el-table-column show-overflow-tooltip type="index" label="序号" width="50">
                    <template slot-scope="scope">
                        {{ (pagination.pageNum - 1) * pagination.pageSize + scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="addressCode" label="地址代码" width="80">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="address" label="地址">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="postcode" label="邮编" width="80">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="facilitieFeatures" label="设施特点" width="130">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="majorActivity" label="主要活动" width="200">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="remark" label="说明">
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="addressState" label="状态" width="60">
                    <template slot-scope="scope">
                        <span class="running" v-show="scope.row.addressState == '01'">有效</span>
                        <span class="replaces" v-show="scope.row.addressState == '02'">无效</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" :current-page="pagination.pageNum"
                @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 50]"
                :page-size="pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="pagination.total">
            </el-pagination>
        </div>
        
        <div class="action-button">
            <el-button class="my-button-green-1" @click="handleSubmit">保 存</el-button>
            <el-button @click="handleClose">取 消</el-button>
        </div>
    </el-dialog>
</template>
<script>
    import { Dialog, Input, Button, Table, TableColumn, Pagination } from 'element-ui';
    export default {
        name: 'KeyPlace',
        components: {
            'el-dialog': Dialog,
            'el-input': Input,
            'el-button': Button,
            'el-table': Table,
            'el-table-column': TableColumn,
            'el-pagination': Pagination,
        },
        data(){
            return {
                title: '选择关键场所',

                visible: false,
                
                queryData: '',
                tableData: [],
                table_loading: false,
                pagination: {
                    pageNum: 1,
                    pageSize: 15,
                    total: 0,
                },
                selectedData: [], //  勾选的数据
                successCallback: null, //成功回调
                successDefaultClose: true, //点击保存 默认关闭

            }
        },
        computed: {
        },
        watch: {
            visible(val){
                console.log('watch, visible', val);
                if (val){
                    this.handleSearch();
                } else {
                    this.queryData = '';
                    this.tableData = [];
                    this.selectedData = [];
                }
            },
        },
        methods: {
            // 获取弹窗设备列表
            getPlaceTableData() {
                this.table_loading = true;
                this.api.postFormAPILab(
                {
                        ...this.pagination,
                        filters: [
                            {
                                fieldName: "testPlace.addressCode",
                                operator: "LIKE",
                                connectOperator: "AND",
                                fieldValue: this.queryData,
                                groupName: "_s",
                            },
                            {
                                fieldName: "testPlace.address",
                                operator: "LIKE",
                                connectOperator: "OR",
                                fieldValue: this.queryData,
                                groupName: "_s",
                            },
                        ],
                        sorters: [
                            {
                                sortField: "testPlace.addressCode",
                                direction: "ASC",
                            },
                        ],
                },
                'queryTestPlace'
            )
            .then((res) => {
                this.table_loading = false;
                this.tableData =res.data.data?.records || [];
                this.pagination.total = res.data.data?.total || 0;
            })
            .catch((err) => console.log(err));
            },
            handleSearch(){
                this.pagination = {
                    ...this.pagination,
                };
                this.getPlaceTableData();
            },
            handleSizeChange(pageSize){
                this.pagination.pageSize = pageSize;
                this.getPlaceTableData();
            },
            handleCurrentChange(pageNum){
                this.pagination.pageNum = pageNum;
                this.getPlaceTableData();
            },
            // 多选框点击
            handleSelectDeviceRow(selection, row){
                let findIndex = this.selectedData.findIndex(item => item.id === row.id);
                this.$refs.table_ref.clearSelection();
                if (findIndex === -1){
                    // 勾选不存在的  push
                    this.$refs.table_ref.toggleRowSelection(row, true);
                    this.selectedData = [{ ...row }];
                } else {
                    // 已存在的  取消所有勾选 勾选
                    this.selectedData = [];
                }
            },
            // 行点击
            handleClickDeviceRow(row){
                this.handleSelectDeviceRow(null, row); //模拟单选框点击
            },
            handleSubmit(){
                if (!this.selectedData.length) {
                    return this.$message.warning("请先选择关键场所");
                }
                typeof this.successCallback === 'function' && this.successCallback(this.selectedData);
                this.successDefaultClose && this.handleClose();
            },
            handleClose(){
                this.visible = false;
            },
            rowClass({ row }) {
                if (this.selectedData.findIndex(item => item.id === row.id) > -1) {
                    return { "background-color": "#ECF5FF" };
                }
            },
        }
    }
</script>
<style lang="less">
    .my-dialog.keyPlace{
        &>.el-dialog{
            height: 85%;
            overflow: hidden!important;
    //         &>.el-dialog__header {
    //     padding: 12px 10px !important;
    //     background-image: linear-gradient(538deg, var(--main-bg-color), #00653E) !important;

    //     &>.el-dialog__title {
    //       font-size: 16px !important;
    //       color: white !important;
    //       font-weight: bold !important;
    //     }

    //     &>.el-dialog__headerbtn {
    //       position: absolute;
    //       top: 20px;
    //       right: 20px;
    //       padding: 0;
    //       background: 0 0;
    //       border: none;
    //       outline: 0;
    //       cursor: pointer;
    //       font-size: 16px;

    //       &>.el-dialog__close {
    //         color: white !important;
    //         font-size: 21px !important;
    //         position: relative !important;
    //         top: -6px !important;
    //       }
    //     }
    //   }
            &>.el-dialog__body{
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: auto 1fr auto;
                overflow: auto;
                &>.search-wrapper{
                    width: 100%;
                    padding: 0 0 0 50%;
                    margin: 0 0 10px;
                    display: flex;
                    justify-content: flex-end;
                }
                &>.table-body{
                    display: flex;
                    flex-flow: column nowrap;
                    overflow: hidden;
                    &>.el-table{
                        overflow-y: auto;
                        flex-grow: 1;
                    }
                    &>.el-pagination{
                        flex-shrink: 0;
                        margin: 10px 6px 10px 0;
                        height: 34px;
                        text-align: right;
                    }
                }
                &>.action-button{
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    // text-align: center;
                    margin: 10px 0;
                    &>.my-button-plain{
                        height: auto;
                        border-radius: 4px;
                        padding: 10px 16px!important;
                    }
                    &>.my-button-green-1{
                        height: auto;
                        padding: 10px 20px!important;
                        font-size: 14px;
                    }
                }
            }
        }
    }
</style>