<!--
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-08-30 17:04:01
 * @FilePath: \product-equip-view\src\views\Index\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEbasic
-->
<template>
	<div id="index">
	<basic-layout>
		<template #[dynamicSlotName]="{user}">
			<h1 >	{{user.name }},Here1 might be a page title</h1>
		</template>
		<template #default="age">
			{{ age.age }}
		  <p>A1 paragraph for the main content2.</p>
     <p>And another one.</p>
		</template>
	
	<template v-slot:footer>
		<p>Here's some contact info</p>
	</template>
	</basic-layout>

	</div>
</template>

<script>
import basicLayout from './basicLayout'
import iconMixin from "./assets/icon.mixin";
import { useForm, usePageWrapper, PATTERNSENUM, PATTERNTYPESENUM, SvgComponentNames } from 'labbol-basic-components/src/components/index';
export default {
	name: 'Index',
	components:{
		basicLayout
	},
	mixins: [iconMixin],
	data(){
		return {
			dynamicSlotName:'header'
		}
	}
}
</script>

<style lang="less">
	#index{

	}
</style>