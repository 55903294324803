//ChoiceDevice.js文件
import ChoiceUploadFiles from './index.vue';
export default {
    install(vm) {
        const $app = vm.extend(ChoiceUploadFiles);
        const $choiceUploadFiles = new $app().$mount(document.createElement('div'))
        document.body.appendChild($choiceUploadFiles.$el)
        vm.prototype.$choiceUploadFiles = {
            show: (params) => {
                Object.keys(params).forEach(key => {
                    $choiceUploadFiles[key] = params[key]
                });
                $choiceUploadFiles.visible = true;
            },
            hide: () => {
                $choiceUploadFiles.visible = false
            }
        }
    }
}