/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-10-21 10:45:52
 * @FilePath: \product-equip-view\src\views\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Index from "./Index/index";
import standardManagement from "./standardManagement";

// -------以下注释为导入导出示例--------
import ItemDivider from '../components/ItemDivider';
import Editor from '../components/Editor';
// import svgComponents from './Index/assets/icon';
// import { addComplexComponent } from "product-custom-form-view";
import svgComponents from './assets/icon';
console.log('svgComponents',svgComponents);
export default {
	/**
	 * @param {Vue} Vue 
	*/
	install(Vue){
		// Vue.component('EntrustTable', EntrustTable);
		// Vue.component('table-icon', svgComponents['table-icon']);
		// addComplexComponent({
		// 	label: '委托详情表格',
		// 	tag: 'componentName',
		// 	tagIcon: 'table-icon',
		// 	defaultValue: []
		// });

		Vue.component('ItemDivider', ItemDivider);
		Vue.component('Editor', Editor);
		Vue.component('expandAndCollapse', svgComponents['expandAndCollapse']);
		Vue.component('treeIcons', svgComponents['treeIcons']);
		Vue.component('folderClose', svgComponents['folderClose']);
		Vue.component('folderOpen', svgComponents['folderOpen']);
		Vue.component('uploads', svgComponents['uploads']);
		Vue.component('more', svgComponents['more']);
	},
}

export {
	/** 子应用 */
	Index,
	standardManagement

	// ------以下为注解示例

	/** 自定义表单组件 */

	/** 表单设计页面  固定路由地址为/custom-form-design */
}