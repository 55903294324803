/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-08 11:41:28
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-11-16 18:07:15
 * @FilePath: \product-equip-view\src\core\token.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import router from "@/router";
import cookie from 'vue-cookie';
import {LoginPath} from '@/core/index';
/** cookie键名枚举 */
const COOKIEKEYS = {
	/** 登录时间戳键名 */
	LOGINSTAMP: 'LOGINSTAMP'
};
/**
 * 获取当前窗口token
 * @returns {String} 当前窗口token
 */
const getWindowToken = () => {
	// return sessionStorage.getItem('token');
	// console.log('getWindowToken', localStorage.getItem('token'));
	if (!cookie.get(COOKIEKEYS.LOGINSTAMP)) {
			removeLocalToken()
	}
	let token = localStorage.getItem("token")
	if (token && token.includes("?token=")) {
			token = token.split("?token=")[0]
	}
	// console.log("fdgdfggfhfhfhfhfh", token)
	return token
}
/**
 * 获取本地token
 */
const getLocalToken = () => {
	if (!cookie.get(COOKIEKEYS.LOGINSTAMP)){
		removeLocalToken();
	}
	return localStorage.getItem('token');
}

/**
 * 设置本地token
 */
const setLocalToken = (token) => {
	cookie.set(COOKIEKEYS.LOGINSTAMP, Date.now());
	localStorage.setItem('token', 'labbol ' + token);
	// location.setItem('noLabbol',token)
}

/**
 * 清除本地token
 */
const removeLocalToken = () => {
	localStorage.removeItem('token');
}

/**
 * 清除当前窗口token 并且进入登录页带上redirect参数  在窗口token存在但不是有效token 或者 窗口token失效时 执行
 */
const removeTokenAndRedirect = () => {
	// sessionStorage.removeItem('token');
	localStorage.removeItem('token');
	if (router.currentRoute.path !== '/'){
		let { redirect, token, ...querys } = router.currentRoute.query;
		let { href } = router.resolve({
			path: router.currentRoute.path,
			query: querys
		});
		router.push({
			path: LoginPath,
			query: {
					redirect: href,
			},
	})
	}
	
}

export {
	getLocalToken,
	getWindowToken,
	setLocalToken,
	removeLocalToken,
	removeTokenAndRedirect,
}