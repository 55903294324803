import { isElectron, ipcRenderer } from '../constant';
import router from '@/router';
/**
 * @param {String} path 路径
 * 打开管理后台
 **/
const openManagementSystem = (path,arg=true) => {
	let token = localStorage.getItem('token').split(' ')[1];
	let href = path +(arg?'?token=' + token:"") ;
	if (isElectron){
		console.log('openExternal', href, ipcRenderer);
		// shell.openExternal(href);
		ipcRenderer.send('openExternal', href);
		// ipcRenderer.send('openExternal', 'http://www.baidu.com');
		// ipcRenderer.send('openExternal', href);
	}
	else {
		window.open(href, "_blank")
	}
}

export {
	openManagementSystem,
}