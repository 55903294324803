/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-23 14:51:14
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-08-23 14:51:52
 * @FilePath: \product-equip-view\src\components\ChoiceStaff\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//ChoiceDevice.js文件
import ChoiceStaff from './index.vue';
export default {
    install(vm) {
        const $app = vm.extend(ChoiceStaff);
        const $choiceStaff = new $app().$mount(document.createElement('div'))
        document.body.appendChild($choiceStaff.$el)
        vm.prototype.$choiceStaff = {
            show: (params) => {
                Object.keys(params).forEach(key => {
                    $choiceStaff[key] = params[key]
                });
                $choiceStaff.visible = true;
            },
            hide: () => {
                $choiceStaff.visible = false
            }
        }
    }
}