<template>
    <div id="choicePersonnel">
        <!-- v-dialogDrag -->
        <el-dialog class="choicePersonnelDialog" :title="title" :visible.sync="visible" :modal-append-to-body="false"
            @close="handleClose" >
            <div class="strrbox">
                <div class="titlebox">
                    <p>已选择 {{ currentOrgName }}</p>
                    <p>
                        已选择
                        <span :style="{ color: selectedUsers.length ? '#07C160' : 'red' }">
                            {{ selectedUsers.length }}
                        </span>
                        项
                    </p>
                </div>
                <div class="leftBox">
                    <el-input v-model="filterOrg" size="small" placeholder="请输入部门名称"></el-input>
                    <el-tree :data="treeData" ref="tree_ref" :props="defaultProps" :expand-on-click-node="false"
                        :current-node-key="currentOrgId" node-key="groupId" highlight-current accordion
                        @node-click="handleNodeClick" :default-expand-all="true">
                        <div :class="['my-custom-label', currentOrgId === data.id ? 'current' : '']"
                            slot-scope="{ node, data }">
                            <span v-html="handleSetFilterHighlight(node.label)"></span>
                        </div>
                    </el-tree>
                    <!-- :filter-node-method="filterNode" -->
                </div>
                <div class="rightBox" v-loading="userLoading">
                    <el-input v-model="filterUser" size="small" placeholder="请输入人员姓名"></el-input>
                    <BasicTable ref="table_ref" v-bind="getTableProps" @row-click="handleRowClick"
                        @select="handleSelect" @select-all="handleSelectAll">

                    </BasicTable>
                    <!-- <el-table
                      ref="table_ref"
                      
                  >
                      <el-table-column show-overflow-tooltip type="selection" :label-class-name="'selection'" label="dfd" :selectable="selectable" width="50"></el-table-column>
                      <el-table-column show-overflow-tooltip type="index" label="序号" width="50">
                          <template slot-scope="scope">
                              {{ (pagination.pageNum - 1) * pagination.pageSize + scope.$index + 1 }}
                          </template>
</el-table-column>
<el-table-column show-overflow-tooltip prop="realName" label="姓名" width="100"></el-table-column>
<el-table-column show-overflow-tooltip prop="username" label="用户名"></el-table-column>
</el-table> -->
                </div>
            </div>
            <div style="margin-left: 970px; margin-top: 10px">
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { Button, Tree, Dialog } from "element-ui"
import { MessageBox, Message } from "element-ui"
import { useTable } from '@/core';
export default {
    name: "choicePersonnel",
    components: {
        "el-button": Button,
        "el-tree": Tree,
        "el-dialog": Dialog,
    },
    data() {
        return {
            title: "选择用户",
            visible: false,
            multiple: false, //多选
            required: true, //是否必须至少选择一项
            originTreeData: [],
            treeData: [],
            filterOrg: "",
            filterTimeout: null,
            currentOrgId: "",
            currentOrgName: "",
            orgTimeout: null,
            selectedUsers: [], //回显数组 通过id,id没有就用realName
            // currentUserId: '',
            // currentUserRealname: '',
            // currentUsername: '',
            filterUser: "",
            userLoading: false,
            pagination: {
                pageNum: 1,
                pageSize: 100,
                total: 0,
            },
            columns: [
                { type: 'selection', width: '55' },
                { type: 'index', label: '序号' },
                { prop: 'realName', label: '姓名', width: '100' },
                { label: "岗位", prop: "post", },
            ],
            userTableData: [],
            userTimeout: null,
            successCallback: null,
            defaultDisabledIds: [], //禁用选择id或realName 数组
            // searchRole: (val) => {
            //     console.log('searchRole', val);
            // },
            // searchPersonnel: (val, orgIds) => {
            //     console.log('searchPersonnel', val, orgIds);
            // },
        }
    },
    created() {
        console.log("created, choicePersonnel")
    },
    mounted() {
        console.log("mounted, choicePersonnel")
    },
    computed: {
        getTableProps() {
            return useTable({
                hideTableSearch: true,
                hideTableToolbar: true,
                data: this.userTableData,
                loading: this.userLoading,
                rowStyle: this.rowClass,
                columns: this.columns,
                defaultRowClick: false

            });
        },
        defaultProps() {
            return {
                label: "groupName",
                children: "children",
                id: "groupId"

            }
        },
        //岗位
        postOptions() {
            return [
                {
                    value: '01',
                    label: '检验人员',
                },
                {
                    value: '02',
                    label: '样品管理员',
                },
                {
                    value: '03',
                    label: '资料档案管理员',
                },
                {
                    value: '04',
                    label: '仪器设备管理员',
                },
                {
                    value: '05',
                    label: '内审员',
                },
                {
                    value: '06',
                    label: '监督员',
                },
                {
                    value: '07',
                    label: '检测部主任',
                },
                {
                    value: '08',
                    label: '技术负责人',
                },
                {
                    value: '09',
                    label: '质量负责人',
                },
                {
                    value: '10',
                    label: '授权签字人',
                },
                {
                    value: '11',
                    label: '最高管理者',
                },
                {
                    value: '12',
                    label: '检测人员',
                },
                {
                    value: '13',
                    label: '校准人员',
                },
                {
                    value: '99',
                    label: '其他',
                },
            ]
        },
    },
    watch: {
        visible(val) {
            console.log("choicePersonnel, watch, visible", val, this.defaultProps, this.title)
            if (val) {
                this.getIscOrgTree();
            } else {
                this.filterOrg = ""
                this.filterUser = ""
                this.currentOrgId = ""
                this.currentOrgName = ""
                this.selectedUsers = []
                this.treeData = []
                this.userTableData = []
                this.defaultDisabledIds = []
                this.required = true
                this.multiple = false
            }
        },
        filterOrg(val) {
            if (!this.visible) return
            this.filterTimeout && window.clearTimeout(this.filterTimeout)
            this.filterTimeout = setTimeout(() => {
                this.handleFilter();
            }, 500)
        },
        currentOrgId(val) {
            if (!val) return
            this.orgTimeout && window.clearTimeout(this.orgTimeout)
            this.orgTimeout = setTimeout(() => {
                this.pagination = {
                    ...this.$options.data().pagination,
                }
                this.getIscUsers()
            }, 500)
        },
        filterUser(val) {
            // if (!val) return
            this.userTimeout && window.clearTimeout(this.userTimeout)
            this.userTimeout = setTimeout(() => {
                this.pagination = {
                    ...this.$options.data().pagination,
                }
                this.getIscUsers()
            }, 500)
        },
        treeData(tree) {
            let isExitTreeDataId = (tree) => {
                return tree[0];
                if (!Array.isArray(tree[0].children) || !tree[0].children.length) {
                    return tree[0];
                }
                else {
                    return isExitTreeDataId(tree[0].children)
                }
            }
            if (Array.isArray(tree) && tree.length) {
                const row = isExitTreeDataId(tree);
                this.$nextTick(() => {
                    this.handleNodeClick(row);
                })
            } else {
                this.userTableData = [];
            }


        }
    },
    methods: {
        handleFilter() {
            console.log("handleFilter", this.originTreeData)
            let target = []
            let getChildren = (children, value) => {
                let temp = []
                if (!(children instanceof Array) || !children.length) return []
                for (let i = 0; i < children.length; i++) {
                    // 当前项是否匹配value   value为空''  也匹配
                    let include = children[i][this.defaultProps.label].includes(value)
                    // 当前项子集
                    let tempChildren = children[i][this.defaultProps.children]
                    // 若不匹配  且当前项子集children不存在 ，为叶子节点  直接continue
                    if (!include && (!(tempChildren instanceof Array) || !tempChildren.length)) continue
                    // 子集递归筛选   当前项  若匹配 则筛选字段为空  若不匹配 筛选为value
                    let subChildren = getChildren(tempChildren, include ? "" : value)

                    // 若当前项不匹配  且当前项子集children不存在  直接continue；
                    if (!include && !subChildren.length) continue
                    let suffix = {}
                    suffix[this.defaultProps.children] = subChildren
                    temp.push({
                        ...children[i],
                        ...suffix,
                    })
                }
                // console.log('temp', temp);
                return temp
            }
            target = getChildren(this.originTreeData, this.filterOrg)
            // console.log('handleFilter', target);
            this.treeData = target
        },
        handleSetFilterHighlight(label) {
            if (!this.filterOrg) return label
            return label.replace(new RegExp(`${this.filterOrg}`, "g"), `<span class="highlight">${this.filterOrg}</span>`)
        },
        handleNodeClick(data) {
            console.log('data', data);
            this.currentOrgId = data.groupId
            this.currentOrgName = data[this.defaultProps.label]
            // this.$refs.tree_ref.setCurrentKey(this.currentOrgId);
            this.$nextTick(() => {
                this.$refs.tree_ref.setCurrentKey(this.currentOrgId)
            })
        },
        deepClone(obj) {
            if (obj === null || typeof obj !== 'object') {
                return obj;
            }

            if (Array.isArray(obj)) {
                const arrCopy = [];
                for (let i = 0; i < obj.length; i++) {
                    arrCopy[i] = this.deepClone(obj[i]);
                }
                return arrCopy;
            }

            const objCopy = {};
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    objCopy[key] = this.deepClone(obj[key]);
                }
            }
            return objCopy;
        },
        async getIscUsers() {
            this.userLoading = true
            let getChildrenOrgIds = (children, include) => {
                // children递归的子集数组  include 对子集是否全部获取orgId
                let orgIds = []
                for (let i = 0; i < children.length; i++) {
                    let subChildren = children[i][this.defaultProps.children]
                    if (!(subChildren instanceof Array) || !subChildren.length) {
                        subChildren = []
                    }
                    if (include || children[i].groupId === this.currentOrgId) {
                        // console.log('include, children[i].id === this.currentOrgId', include, children[i].id, children[i][this.defaultProps.label]);
                        orgIds = [...orgIds, children[i].groupId, ...getChildrenOrgIds(subChildren, true)]
                        if (!include) break
                    } else {
                        if (!subChildren.length) continue
                        orgIds = getChildrenOrgIds(subChildren, false)
                        if (orgIds.length) break
                    }
                }
                return orgIds
            }
            let orgIds = getChildrenOrgIds(this.originTreeData, false);
            let res = await this.searchPersonnel(this.filterUser, orgIds);
            let tempData = res.records || [];
            // 处理岗位数据
            tempData = tempData.map(item => {
                let postIds = item.post.split(',');
                let post = postIds.map(id => {
                    let option = this.postOptions.find(option => option.value === id);
                    return option ? option.label : '';
                }).filter(label => label).join(', ');

                return {
                    ...item,
                    post,
                };
            });
            this.userTableData = tempData;
            this.pagination.total = res.total;
            this.userLoading = false;
            for (let i = 0; i < this.selectedUsers.length; i++) {
                let findIndex = this.userTableData.findIndex(item => item.id === this.selectedUsers[i].id || item.realName === this.selectedUsers[i].realName)
                if (findIndex > -1) {
                    console.log("findIndex", findIndex)
                    this.$nextTick(() => {
                        this.$refs.table_ref.toggleRowSelection(this.userTableData[findIndex], true)
                    })
                }
            }
            this.userLoading = false
        },
        getIscOrgTree() {
            // /v1.0/iscuap/co/getIscOrgTree
            return new Promise(resolve => {
                this.api.getListAPILab(
                    '',
                    'common/select/getIscOrgTree'
                ).then(res => {
                    this.treeData = res.data.data || [];
                    this.originTreeData = res.data.data || [];

                }).catch(error => {
                    console.log('getIscOrgTree, error', error);
                    this.treeData = [];
                })
            })
        },
        searchPersonnel(filterUser, orgIds) {
            return new Promise((resolve) => {
                this.api.postFormAPIStaff(
                    {
                        pageNum: 1,
                        pageSize: 99999,
                        filters: [
                            {
                                fieldName: "org.id",
                                operator: "IN",
                                connectOperator: "AND",
                                fieldValue: orgIds,
                            },
                            {
                                fieldName: 'staffInfo.realname',
                                operator: 'LIKE',
                                connectOperator: 'AND',
                                fieldValue: filterUser,
                                groupName: '_S',
                            },
                            {
                                fieldName: 'staffInfo.staffNo',
                                operator: 'LIKE',
                                connectOperator: 'OR',
                                fieldValue: filterUser,
                                groupName: '_S',
                            },
                        ],
                        sorters: [
                            {
                                sortField: 'staffInfo.createTime',
                                direction: 'DESC',
                            },
                        ],

                    },
                    'queryStaffInfo'
                ).then(res => {
                    resolve(res.data.data)
                }).catch(error => {
                    resolve(false);
                })
            })


        },
        handleSizeChange(pageSize) {
            this.pagination.pageSize = pageSize
            this.pagination.pageNum = 1
            this.getIscUsers()
        },
        handleCurrentChange(pageNum) {
            this.pagination.pageNum = pageNum
            this.getIscUsers()
        },
        selectable(row) {
            return !this.defaultDisabledIds.includes(row.id) && !this.defaultDisabledIds.includes(row.realName)
        },
        handleRowClick(row) {
            if (!row) return;
            console.log("handleRowClick", this.defaultDisabledIds, this.defaultDisabledIds.includes(row.id))
            if (!this.selectable(row)) {
                // this.$message({
                //     type: 'warning',
                //     message: `${ row.realName }不能同时是检测员和校核员`
                // });
                return
            }
            // if(!this.defaultDisabledIds.includes(row.id)){
            //     this.handleSelect("", row)
            // }
            this.handleSelect("", row)
        },
        handleSelect(selection, row) {
            // 查询点击行 是否在selectedUsers 中
            let findIndex = this.selectedUsers.findIndex(item => (item.id && item.id === row.id) || (!item.id && item.realName === row.realName))
            console.log("handleSelect", row.id, findIndex)
            if (findIndex > -1) {
                // 点击或勾选的是 已存在 的行，则要取消
                if (!this.multiple) {
                    //单选 要先清除其他勾选
                    this.$refs.table_ref.clearSelection()
                }
                this.$refs.table_ref.toggleRowSelection(row, false)
                this.selectedUsers.splice(findIndex, 1)
            } else {
                // 点击或勾选的是 不存在 的行，则要勾选
                if (!this.multiple) {
                    //单选 要先清除其他勾选
                    this.$refs.table_ref.clearSelection()
                    this.selectedUsers = []
                }
                this.$refs.table_ref.toggleRowSelection(row, true)
                this.selectedUsers.push({
                    ...row,
                })
            }
        },
        handleSelectAll(selection) {
            if (!this.multiple) return
            let temp = this.selectedUsers.map(item => {
                return { ...item }
            })
            if (selection.length) {
                temp = [
                    ...temp,
                    ...selection.map(item => {
                        return {
                            ...item,
                        }
                    }),
                ]
                console.log("handleSelectAll", selection, temp)
                this.selectedUsers = temp.filter((item, index) => index === temp.findIndex(Item => (item.id && Item.id === item.id) || (!item.id && Item.realName === item.realName)))
                console.log("handleSelectAll.selectedUsers", this.selectedUsers)
            } else {
                this.selectedUsers = temp.filter((item, index) => index !== this.userTableData.findIndex(Item => (item.id && item.id === Item.id) || (!item.id && item.realName === Item.realName)))
            }
        },
        //表格选中背景色
        rowClass({ row }) {
            if (this.selectedUsers.findIndex(item => item.id === row.id) > -1) {
                return { "background-color": "#ECF5FF" }
            }
        },
        handleClose() {
            this.userTableData = []
            this.visible = false
        },
        handleSubmit() {
            console.log("handleSubmit", this.required)
            if (this.required && !this.selectedUsers.length) {
                return MessageBox.alert("至少选择一项！", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: true,
                    customClass: "labMessageClass",
                })
                    .then(() => { })
                    .catch(() => { })
            }

            let data = this.selectedUsers.map(item => {
                return {
                    ...item,
                }
            })
            if (typeof this.successCallback === "function") {
                this.successCallback(data)
            }
            this.visible = false
            console.log("handleSubmit", data)
        },
    },
}
</script>
<style lang="less">
#choicePersonnel {
    &>.choicePersonnelDialog {
        &>.el-dialog {
            min-width: 1200px;
            height: 650px;

            & div {
                box-sizing: border-box;
            }


            &>.el-dialog__header {
                flex-shrink: 0;
                padding: 15px 15px 10px !important;
                border-bottom: 2px solid #ebeef5;
                font-weight: bold;
                color: #303133;
                font-size: 14px;
                line-height: 1;
                position: relative;
                background-image:linear-gradient(538deg, #fff, #fff) !important;

                & .el-dialog__title {
                    font-size: 14px;
                    color: #303133 !important;
                }
            }

            &>.el-dialog__body {
                height: calc(100% - 48px);
                display: flex;
                flex-flow: column nowrap;

                &>.strrbox {
                    flex-grow: 1;
                    overflow: auto;
                    display: flex;
                    // width: 98% !important;
                    height: 100%;
                    margin: 20px auto 0;

                    // margin-top: 30px;
                    &>.titlebox {
                        width: 95% !important;
                        position: absolute !important;
                        top: 65px !important;
                        height: 20px !important;

                        p:first-child {
                            width: 48% !important;
                            float: left !important;
                            height: 24px !important;
                            margin-right: 5% !important;
                            font-weight: 600 !important;
                            color: #000 !important;
                        }

                        p:last-child {
                            width: 47% !important;
                            float: left !important;
                            height: 24px !important;
                            font-weight: 600 !important;
                            color: #000 !important;
                            margin-left: -80px !important;
                        }
                    }

                    &>.leftBox {
                        // width: 49% !important;
                        flex-grow: 1;
                        flex-shrink: 0;
                        min-width: 500px;
                        height: 100% !important;
                        overflow: hidden !important;
                        overflow-y: auto !important;
                        margin-right: 2% !important;
                        border: #ccc solid 1px !important;

                        &>.el-input {
                            margin: 15px 16px 10px;
                            width: calc(100% - 32px);

                            &>.el-input__inner {

                                &:focus,
                                &:hover {
                                    border: 1px solid #0a8a55;
                                }
                            }
                        }

                        &>.el-tree {
                            & .el-tree-node {
                                &.is-current {
                                    &>.el-tree-node__content {
                                        background: var(--main-bg-color) !important;
                                        color: #fff;

                                        &>.my-custom-label {
                                            & .highlight {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }

                                &>.el-tree-node__content {
                                    &:hover {
                                        background: var(--main-bg-color) !important;
                                        color: #fff;

                                        &>.my-custom-label {
                                            & .highlight {
                                                color: #fff;
                                            }
                                        }
                                    }

                                    &>.el-tree-node__expand-icon {
                                        &.is-leaf {
                                            background-color: unset !important;
                                        }
                                    }

                                    &>.my-custom-label {
                                        & .highlight {
                                            color: var(--main-bg-color);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &>.rightBox {
                        // width: 49% !important;
                        // flex-grow: 1;
                        width: 600px;
                        height: 100% !important;
                        overflow: hidden;
                        border: #ccc solid 1px !important;
                        padding: 15px 16px 20px;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: stretch;

                        &>.el-input {
                            flex-shrink: 0;

                            &>.el-input__inner {

                                &:focus,
                                &:hover {
                                    border: 1px solid #0a8a55;
                                }
                            }
                        }

                        &>.el-table {
                            margin: 10px 0 5px 0;
                            flex-grow: 1;
                            overflow: auto;

                            & .cell {
                                &.selection:not(.multiple) {
                                    display: none;
                                }
                            }
                        }

                        &>.el-pagiantion {
                            flex-shrink: 0;
                        }
                    }
                }

                &>.el-button {
                    margin-right: 1%;
                    align-self: flex-end;
                }
            }
        }
    }
}
</style>
