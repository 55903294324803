//ChoiceKeyPlace.js文件
import Vue from 'vue'
import ChoiceKeyPlace from './index.vue';




export default {
    install(vm) {
        const $app = vm.extend(ChoiceKeyPlace);
        const $choiceKeyPlace = new $app().$mount(document.createElement('div'))
        document.body.appendChild($choiceKeyPlace.$el)
        vm.prototype.$choiceKeyPlace = {
            show: (params) => {
                Object.keys(params).forEach(key => {
                    $choiceKeyPlace[key] = params[key]
                });
                $choiceKeyPlace.visible = true;
            },
            hide: () => {
                $choiceKeyPlace.visible = false
            }
        }
    }
}