<template>
  <!-- 富文本 -->
  <div>
    <editor v-model="content" :init="init" :disabled="disabled"></editor>
  </div>
</template>


  <script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
//   import "tinymce/icons/default/icons";
import "tinymce/themes/silver";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/preview";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/advlist";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/charmap";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/autosave";
import "tinymce/plugins/autoresize";

export default {
  name: "tinymce-editor",
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
        "preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media code codesample table charmap hr nonbreaking insertdatetime advlist lists wordcount imagetools textpattern autosave autoresize",
    },
    toolbar: {
      type: [String, Array],
      default:"code undo redo restoredraft |  forecolor bold italic underline | alignleft aligncenter alignright alignjustify outdent indent | styleselect formatselect fontselect fontsizeselect",
      // 图片和视频  image media
    },
  },
  data() {
    return {
      //初始化配置
      init: {
        selector: '#tinymce',
        // menubar: true, // 菜单栏显隐
        language_url: `${process.env.BASE_URL}tinymce/langs/zh_CN.js`,
        language: "zh_CN",
        content_css: `${process.env.BASE_URL}tinymce/skins/content/default/content.css`,
        skin_url: `${process.env.BASE_URL}tinymce/skins/ui/oxide`,
        min_height: 500,
        // max_height: 500,
        // height:500,
        // toolbar_mode: "floating",
        plugins: this.plugins,
        toolbar: this.toolbar,
        elementpath:false,
        content_style: "p {margin: 5px 0;}",
        fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        branding: false,
        paste_preprocess: function (plugin, args) {
                    console.log(args.content);
                },
                paste_as_text: true,
                //init_instance_callback为回调配置项
                init_instance_callback: (editor) => {
                    // console.log(`回调----`)
                    editor.on('input', e => {
                        // console.log('文本框input触发')
                        // this.$emit('input', e.target)
                        // console.log(e.target, this.formData.activeTarget);
                    });
                    editor.on('change', e => {
                        // console.log('文本框change触发')
                        // this.$emit('change', e.level.content)
                        // console.log(e.level, this.formData.jobRequirement);
                    })
                },
      },
      content: this.value,
    };
  },
  mounted() {
    tinymce.init({});
  },
  methods: {},
  watch: {
    value(newValue) {
      this.content = newValue;
    },
    content(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

