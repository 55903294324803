<template>
<div  class="lab-form-divider">
  <div class="lab-form-divider--title">
    {{ title }}
  </div>
  <div class="lab-form-divider__append">
     <slot name="append"></slot>
  </div>
  </div>
</template>

<script>
export default {
  name: 'ItemDivider',
  props:{
    title:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>
<style lang="less" scoped>
.lab-form-divider {
    padding: 0 0 0 15px;
    color: #333;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        position: absolute;
        left: 0;
        content: '';
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background: var(--main-bg-color);
    }

    &--title {
        font-size: 18px;
        color: #000;
        font-weight: bold;
        flex-grow: 1;
    }

    &__append {
        flex-shrink: 0;
    }
}
</style>