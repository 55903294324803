/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-08-13 11:36:06
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-10-18 10:41:05
 * @FilePath: \product-equip-view\src\components\ChoicePersonnel\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//choicePersonnel.js文件
import choicePersonnel from "./index.vue";



export default {
  install(vm) {
    const $app = vm.extend(choicePersonnel);

    const $choicePersonnel = new $app().$mount(document.createElement("div"));

    document.body.appendChild($choicePersonnel.$el);
    vm.prototype.$choicePersonnel = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $choicePersonnel[key] = params[key];
        });
        $choicePersonnel.visible = true;
      },
      hide: () => {
        $choicePersonnel.visible = false;
      },
    };
  },
};
