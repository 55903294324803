/*
 * @Author: 余生 906524998@qq.com
 * @Date: 2024-09-02 16:52:43
 * @LastEditors: 余生 906524998@qq.com
 * @LastEditTime: 2024-10-18 10:37:51
 * @FilePath: \product-equip-view\src\components\ChoiceDevice\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//ChoiceDevice.js文件
import ChoiceDevice from './index.vue';

export default {
    install(vm) {
        const $app = vm.extend(ChoiceDevice);
        const $choiceDevice = new $app().$mount(document.createElement('div'))
        document.body.appendChild($choiceDevice.$el)
        vm.prototype.$choiceDevice = {
            show: (params) => {
                Object.keys(params).forEach(key => {
                    $choiceDevice[key] = params[key]
                });
                $choiceDevice.visible = true;
            },
            hide: () => {
                $choiceDevice.visible = false
            }
        }
    }
}