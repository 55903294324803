//choicePersonnelByRoleGroup.js文件
import choicePersonnelByRoleGroup from "./index.vue";
export default {
  install(vm) {
    const $app = vm.extend(choicePersonnelByRoleGroup);

    const $choicePersonnelByRoleGroup = new $app().$mount(document.createElement("div"));

    document.body.appendChild($choicePersonnelByRoleGroup.$el);
    vm.prototype.$choicePersonnelByRoleGroup = {
      show: (params) => {
        Object.keys(params).forEach((key) => {
          $choicePersonnelByRoleGroup[key] = params[key];
        });
        $choicePersonnelByRoleGroup.visible = true;
      },
      hide: () => {
        $choicePersonnelByRoleGroup.visible = false;
      },
    };
  },
};
