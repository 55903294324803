import Vue from "vue"
import Vuex from "vuex";
import router from '@/router';
import api from '@/api/api';
import {
    removeLocalToken, getLocalToken
} from '@/core/token';
import { isElectron } from '@/core/Electron';
import {LoginPath} from '@/core/index';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {
            id: '',
            userName: '',
            tenantId: '',
            mobileNumber: '',
            roleList: '',
            memberId: '',
            realName: '',
            isAdmin: '',
            orgName: '',
            orgId: '',
        },
        // 字典
        dataDictionary: {
            // 归属表单
            formAttr: [{
                label: "任务表单",
                value: "01"
            },
            {
                label: "环境表单",
                value: "02"
            },
            ],
            // 表单状态
            formStatus: [{
                label: '发布',
                value: '01',
                style: {
                    color: "#058E44"
                },
            },
            {
                label: '作废',
                value: '02',
                style: {
                    color: "#D9001B"
                },
            },
            {
                label: '草稿',
                value: '03',
                style: {
                    color: "#E6D540"
                },

            },
            ],
            /** 模板管理-模板库  模板类型 */
            templateType: [
                { label: '通用模板', value: '01' },
                { label: '项目模板', value: '02' },
                { label: '其他模板', value: '03' },
                { label: '文档结构', value: '04' },
            ],
            /** 模板管理-模板库  模板状态 */
            templateStatus: [
                { label: '发布', value: '01', style: { color: '#058E44' } },
                { label: '草稿', value: '02', style: { color: '#E6D540' } },
                { label: '作废', value: '03', style: { color: '#D9001B' } },
            ],
        },
        productInfo:{
            productCode: 'Tqs',
        },
        /** 窗口是否最大化 应用在Electron环境中 */
        isMaximized: false,
        documentTitle: '质量管理体系',
    },
    mutations: {
        setUserInfo(state, userInfo) {
            let {
                token,
                ...params
            } = userInfo;
            // let { id, isAdmin, tenantId } = params;
            state.userInfo = {
                ...params || {}
            }
            console.log('setUserInfo', state.userInfo);
        },
        /** electron环境下 窗口最大化 或者 还原 */
        setIsMaximized(state, isMaximized) {
            // console.log('setIsMaximized', isMaximized);
            state.isMaximized = isMaximized;
        },
    },
    actions: {
        async getUserInfo({
            commit
        }) {
            return new Promise((resolve, reject) => {
                api.getListAPItenant(
                    {
                        // userToken: getLocalToken(),
                        productCode: "Tqs"
                    },
                    'getLoginUser'
                ).then(res => {
                    console.log('commit.getLoginUser111', res.data.data);
                    commit('setUserInfo', res.data.data);
                    resolve();
                }).catch(error => {
                    console.log('error', error);
                    resolve();
                });
            })
        },
        async logout({
            commit
        }) {
            return new Promise((resolve, reject) => {
                api.postFormAPISso(
                    '',
                    "logout",
                    '',
                    {
                        showSuccessMessage: true,
                    }
                ).then(() => {
                    removeLocalToken();
                    router.push({
                        path: LoginPath
                    })
                })
                    .catch(error => { })
            })
        }
    },
    modules: {},
})